import React, {useContext, useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {ConstLink} from "../../ConstLink";
import Bluey from "../../Components/Bluey"
import { Row, Col } from "react-bootstrap"
import logError from "../../Components/Logger";  //  error handler

export default function Classroom() {

    const navigate = useNavigate();
    const location = useLocation(); //error handler
    const [moduleList, setModuleList] = useState(JSON.parse(window.sessionStorage.getItem("currentClassroom")));
    const [certificate, setCertificate] = useState(window.sessionStorage.getItem("currentCertificate"));
    const [certCode, setCertCode] = useState();
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [loading, setLoading] = useState(true);
    const { isLoggedIn } = useContext(ConstLink);
    const studentID = window.sessionStorage.getItem("studentId");

    // State to manage open/closed state of each accordion row
    const [expandedRows, setExpandedRows] = useState({});

    // Function to toggle the accordion row's open/closed state
    const toggleAccordion = (index) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    // Wrapper function to decide which format function to use
    const getOutcomeOrStatus = (proposedOutcome, statusID) => {
        if (proposedOutcome === null || proposedOutcome === undefined || proposedOutcome === '') {
            return formatProposedStatus(statusID);
        }
        return formatProposedOutcome(proposedOutcome);
    };

    const formatProposedOutcome = (outcome) => {
        switch (outcome) {
            case 20:
                return `COMPLETED`;
            case 30:
                return 'IN PROGRESS';
            case 40:
                return 'WITHDRAWN';
            case 51:
                return 'RPL APPROVED';
            case 52:
                return 'RPL DENIED';
            case 60:
                return `CREDIT TRANSFERRED`;
            case 61:
                return 'SUPERSEDED';
            case 70:
                return 'IN PROGRESS';
            case 81:
                return 'COMPLETED';
            case 82:
                return 'WITHDRAWN';
            case 85:
                return 'NOT YET STARTED'
            case 90:
                return 'IN PROGRESS';
            default:
                return `N/A`;
        }
    };

    const formatProposedStatus = (outcome) => {
        switch (outcome) {
            case 1:
                return `NOT YET ENROLLED`;
            case 2:
            case 3:
            case 4:
                return `ENROLLED`;
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `COMPETENT`;
            case 10:
                return `WITHDRAWN`;
            case 11:
                return `UNKNOWN`;
            case 13:
                return `COMPETENT`;
            case 14:
                return `COMPLETED`;
            default:
                return 'N/A';
        }
    };

    const formatButton = (proposedOutcome, statusID) => {
        let outcome = proposedOutcome;

        if (proposedOutcome === null || proposedOutcome === undefined || proposedOutcome === '') {
            outcome = statusID;
        }

        switch (outcome) {
            case 1:
                return { text: 'NOT AVAILABLE', disabled: true };
            case 2:
            case 3:
            case 4:
                return { text: 'START ASSESSMENT', disabled: false };
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return { text: 'COMPLETED', disabled: true };
            case 10:
                return { text: 'NOT AVAILABLE', disabled: true };
            case 11:
                return { text: 'NOT AVAILABLE', disabled: true };
            case 13:
                return { text: 'COMPLETED', disabled: true };
            case 14:
                return { text: 'COMPLETED', disabled: true };
            case 20:
                return { text: 'COMPLETED', disabled: true };
            case 30:
                return { text: 'CONTINUE', disabled: false };
            case 40:
                return { text: 'CONTACT TRAINER', disabled: true };
            case 51:
                return { text: 'COMPLETED', disabled: true };
            case 52:
                return { text: 'CONTACT TRAINER', disabled: true };
            case 60:
                return { text: 'COMPLETED', disabled: true };
            case 61:
                return { text: 'Contact Trainer', disabled: false };
            case 70:
                return { text: 'Assessment', disabled: false };
            case 81:
                return { text: 'COMPLETED', disabled: true };
            case 82:
                return { text: 'CONTACT TRAINER', disabled: true };
            case 85:
                return { text: 'START ASSESSMENT', disabled: false };
            case 90:
                return { text: 'CONTINUE', disabled: false };
            default:
                return { text: 'NOT AVAILABLE', disabled: true };
        }
    };


    // *** Dont delete *** //
    const handleNavigate = (modID, certificate, modCode, modName) => {

        const jsonObject = { modId: modID };
        window.sessionStorage.setItem("currentClassroom", modID);
        window.sessionStorage.setItem("currentCertificate", certificate);
        window.sessionStorage.setItem("currentModuleCode", modCode);
        window.sessionStorage.setItem("currentModuleName", modName);

        navigate('/study/classroom/assessment', { state: { jsonObject }, replace: true });

        // Navigate with State method
        // navigate('/study/classroom/assessment', { state: { jsonObject } });

        // Navigate with JSON object
        // const serializedData = encodeURIComponent(JSON.stringify(yourJsonObject));
        // navigate(`/study/assessment?data=${serializedData}`);

        // Navigate with string
        // const message = "Hello, V!";
        // navigate(`/study/assessment?message=${encodeURIComponent(message)}`);
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);


    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = date.toLocaleDateString('en-GB', options); // Example: "7 May 1984"

        // Extract day number to append the ordinal suffix
        const day = date.getDate();
        const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || ["th", "st", "nd", "rd"][day % 100] || "th";

        // Replace the day number with the number+suffix
        formattedDate = formattedDate.replace(day, `${day}${suffix}`);

        return `${formattedDate}`;
    }
    const fetchCertDetails = async (certID) => {
        const response = await fetch('https://api.trainingprofessionals.com.au/student/fetch_cert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ certID }),
            credentials: 'include'  // This ensures cookies are sent with the request
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error fetching certificate details:', errorData.message);
            return;
        }

        const result = await response.json();
        // console.log('Certificate details fetched:', result.data);

        if (result.data && result.data.length > 0) {
            const certCode = result.data[0].code;
            // console.log('Certificate code:', certCode);
            setCertCode(certCode);
        } else {
            console.log('No certificate details found');
        }

        return result.data;
    };


    useEffect(() => {

        // console.log('Certificate ID:', certificate);
        console.log('Module List:', moduleList);

        fetchCertDetails(moduleList[0].certID)

        // console.log('Certificate Code:', certCode);

    }, []);

    return (
        <div>

            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                <div style={{width: '100%', padding: 15, justifyContent: 'center', alignItems: 'center' }}>

                    <Row>
                        <Col xs={12} md={12} xl={12} style={{ marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <h1 style={{fontSize: 24, fontWeight: 'bold', color: '#333333'}}>{certificate}</h1>
                        </Col>
                        {/*<Col xs={12} md={12} xl={12} style={{marginTop: 50, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>*/}
                        {/*    <button onClick={() => handleNavigate(4757)} className={'btn-primary'} style={{width: '200px', marginTop: 50}}>Test Assessment</button>*/}
                        {/*</Col>*/}
                    </Row>

                    <Row style={{ width: '100%', margin: '50px auto', textAlign: 'start', padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <table style={{width: '100%', padding: 25}}>
                            <thead>
                            <tr style={{ fontSize: 24}}>
                                <th>Code</th>
                                <th>Module Name</th>
                                <th style={{ textAlign: 'center' }}>Status</th>
                                <th style={{ textAlign: 'center' }}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {moduleList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr onClick={() => toggleAccordion(index)}
                                            className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                            <td>{row.modDetails.code}</td>
                                            <td>{row.modDetails.name}</td>
                                            <td style={{textAlign: 'center'}}>{getOutcomeOrStatus(row.proposedOutcome, row.statusID)}</td>
                                            <td style={{textAlign: 'center'}}>
                                                {(() => {
                                                    const buttonInfo = formatButton(row.proposedOutcome, row.statusID);
                                                    return (
                                                        <button
                                                            onClick={() => handleNavigate(row.modID, certCode, row.modDetails.code, row.modDetails.name)}
                                                            className="btn-table"
                                                            disabled={buttonInfo.disabled}
                                                            style={{
                                                                cursor: buttonInfo.disabled ? 'not-allowed' : 'pointer',
                                                                backgroundColor: buttonInfo.disabled ? '#b7b7b7' : '#393939',
                                                                color: buttonInfo.disabled ? '#666666' : '#ffffff',
                                                            }}
                                                        >
                                                            {buttonInfo.text}
                                                        </button>
                                                    );
                                                })()}
                                            </td>
                                        </tr>
                                        {expandedRows[index] && (
                                            <tr className="no-hover">
                                                <td colSpan={4} style={{paddingTop: 0, padding: 0}}>
                                                    <div style={{ }}>
                                                        <div style={{flex: 1}}>
                                                            <p><strong>Description:</strong></p>
                                                            <p>{row.modDetails.unitDescriptor}</p>
                                                            {/*<p><strong>Application:</strong></p>*/}
                                                            <p>{row.modDetails.applicationOfTheUnit}</p>
                                                            <p><strong>Start Date:</strong></p>
                                                            <p>{formatDate(row.actualStartDate)}</p>
                                                            <p><strong>Completion Date:</strong></p>
                                                            <p>{formatDate(row.actualEndDate) || 'N/A'}</p>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                </div>
            </div>
        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //