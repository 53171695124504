// Import styling
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styling/index.css';

// Routing
import { BrowserRouter as Router, Routes ,Route } from "react-router-dom";
import { ConstProvider } from 'ConstLink';
import { useEffect } from 'react';

// Pages
import Login from "Pages/Login";
import Enrol from "Pages/Enrol";
import Home from "Pages/Home";
import CoursesTraining from "Pages/CoursesTraining";
import Contact from "Pages/Contact";
import Employment from "Pages/Employment";
import Competencies from "Pages/Competencies";
import PersonalDetails from "Pages/Study/PersonalDetails";
import Certificate from "Pages/Study/Certificate";
import Study from "Pages/Study";
import Diary from "Pages/Study/Diary";
import Template from "Pages/Template";
import Test from "Pages/Test";
import Register from "Pages/Enrol";
import Assessment from "Pages/Study/Assessment";
import Enrolment from "Pages/Study/Enrolment";
import Event_Viewer from "./Pages/Event_Viewer";
import BuilderPage from "./Pages/builder-page";
import Classroom from "./Pages/Study/Classroom";

// Components
import Header from "./Components/Header";
import Footer from "./Components/Footer";

// git push


import React, { useState } from "react";
function App() {

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : process.env.REACT_APP_FB_APP_ID,
        cookie     : true,
        xfbml      : true,
        version    : 'v10.0'
      });

      // Additional setup here if needed
    };

    // Load the SDK script
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Initiate Google client SDK
    // const initClient = () => {
    //     window.gapi.load('auth2', function() {
    //         window.gapi.auth2.init({
    //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //             scope: 'profile email'
    //         });
    //     });
    // };

  }, []);

  return (

      <Router>
        <div className="App">

          <ConstProvider>

            <header className="App-header">
              <div className="header-overflow"><Header /></div>
            </header>

            <Routes>

              <Route path="/" element={<Home/>} />
              <Route path="/Login" element={<Login/>} />
              <Route path="/Enrol" element={<Enrol/>} />
              <Route path="/Template" element={<Template/>} />
              <Route path="/Contact" element={<Contact/>} />
              <Route path="/Study" element={<Study/>} />
              <Route path="/Study/Classroom" element={<Classroom/>} />
              <Route path="/Employment" element={<Employment/>} />
              <Route path="/Competencies" element={<Competencies/>} />
              <Route path="/Study/Personal-Details" element={<PersonalDetails/>} />
              <Route path="/Study/Diary" element={<Diary/>} />
              <Route path="/Study/Certificate" element={<Certificate/>} />
              <Route path="/Study/Classroom/Assessment" element={<Assessment/>} />
              <Route path="/Courses-Training" element={<CoursesTraining/>} />
              <Route path="/Test" element={<Test/>} />
              <Route path="/Register" element={<Register/>} />
              <Route path="/Study/Enrolment" element={<Enrolment/>} />
              <Route path="/Event_viewer" element={<Event_Viewer/>} />
              <Route path="/builder-demo" element={<BuilderPage />} />
            </Routes>

            <footer>
              <Footer />
            </footer>
          </ConstProvider>
        </div>
      </Router>
  );
}

export default App;

