import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import SignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';
import samplePdf from './CoverSheetEdit.pdf'; // Adjust the path to your PDF file
import { jsPDF } from 'jspdf';
import DatePicker from 'react-datepicker';
import { Portal } from "react-overlays";
import 'react-datepicker/dist/react-datepicker.css';

// Set the worker source to the public directory
GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

const A4_WIDTH = 210;  // mm
const A4_HEIGHT = 297; // mm

const SignaturePanel = ({ annotations }) => {


    const today = dayjs().toDate();
    const [dates, setDates] = useState({ Date: today }); // Store dates for inputs with 'Date' initialized to today
    const pdfCanvasRef = useRef(null);
    const sigCanvasRef = useRef({});
    const renderTaskRef = useRef(null);
    const [signature, setSignature] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const inputRefs = useRef([]);
    const [initialPdfCanvasDimensions, setInitialPdfCanvasDimensions] = useState({ width: 0, height: 0 });
    const pdfCanvasHeight = pdfCanvasRef.current ? pdfCanvasRef.current.height : 1;
    const pdfCanvasWidth = pdfCanvasRef.current ? pdfCanvasRef.current.width : 1;
    const [isDrawingEnabled, setIsDrawingEnabled] = useState(window.innerWidth > 1000); // State for drawing canvas visibility

    useEffect(() => {

        updatePenWidths();

        // const element = document.querySelector('.scalable-element');
        // let initialDistance = null;
        // let initialScale = 1;
        //
        // const handleTouchStart = (e) => {
        //     if (e.touches.length === 2) {
        //         initialDistance = getPinchDistance(e.touches);
        //         initialScale = parseFloat(element.style.transform.replace(/scale\(([^)]+)\)/, '$1')) || 1;
        //     }
        // };
        //
        // const handleTouchMove = (e) => {
        //     if (e.touches.length === 2) {
        //         e.preventDefault();
        //         const currentDistance = getPinchDistance(e.touches);
        //         const scale = initialScale * (currentDistance / initialDistance);
        //         element.style.transform = `scale(${scale})`;
        //     }
        // };
        //
        // const handleTouchEnd = (e) => {
        //     if (e.touches.length < 2) {
        //         initialDistance = null;
        //     }
        // };
        //
        // const getPinchDistance = (touches) => {
        //     const [touch1, touch2] = touches;
        //     const dx = touch2.clientX - touch1.clientX;
        //     const dy = touch2.clientY - touch1.clientY;
        //     return Math.sqrt(dx * dx + dy * dy);
        // };
        //
        // element.addEventListener('touchstart', handleTouchStart, { passive: false });
        // element.addEventListener('touchmove', handleTouchMove, { passive: false });
        // element.addEventListener('touchend', handleTouchEnd);
        //
        // return () => {
        //     element.removeEventListener('touchstart', handleTouchStart);
        //     element.removeEventListener('touchmove', handleTouchMove);
        //     element.removeEventListener('touchend', handleTouchEnd);
        // };

    }, []);

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    const calculateFontSize = (canvasHeight, dpr) => {
        const baseFontSize = (canvasHeight * 0.014) / dpr; // Halve the base font size calculation and normalize by DPR
        // console.log(`Canvas height: ${canvasHeight}px, Base font size: ${baseFontSize}px, DPR: ${dpr}`);
        return baseFontSize;
    };

    const calculatePenWidths = (canvasHeight, isMobile) => {
        const baseMinWidth = 0.25; // Base minimum pen width
        const baseMaxWidth = 1; // Base maximum pen width
        const scaleFactor = canvasHeight / 1000; // Adjust the denominator to your base canvas height
        const mobileScaleFactor = isMobile ? 0.25 : 1; // Halve the pen widths on mobile

        return {
            minWidth: Math.max(baseMinWidth * scaleFactor * mobileScaleFactor, 0.25), // Ensure minimum width of 0.25px
            maxWidth: Math.max(baseMaxWidth * scaleFactor * mobileScaleFactor, 1), // Ensure minimum width of 1px
        };
    };

    const updatePenWidths = () => {
        if (sigCanvasRef.current) {
            const canvasHeight = pdfCanvasRef.current.height;
            const dpr = window.devicePixelRatio || 1;
            const isMobile = window.innerWidth <= 768; // Example breakpoint for mobile devices
            const { minWidth, maxWidth } = calculatePenWidths(canvasHeight / dpr, isMobile);

            sigCanvasRef.current.off(); // Turn off existing event listeners
            sigCanvasRef.current.on(); // Re-attach event listeners with new pen widths
            sigCanvasRef.current.getCanvas().getContext('2d').minWidth = minWidth;
            sigCanvasRef.current.getCanvas().getContext('2d').maxWidth = maxWidth;
        }
    };

    const loadPdf = async () => {
        if (renderTaskRef.current) {
            try {
                await renderTaskRef.current.cancel();
                renderTaskRef.current = null;
            } catch (error) {
                return;
            }
        }

        try {
            const pdf = await getDocument(samplePdf).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1 });

            const canvas = pdfCanvasRef.current;
            const context = canvas.getContext('2d');
            const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;

            const devicePixelRatio = window.devicePixelRatio || 1;
            const scale = containerWidth / viewport.width;

            canvas.width = viewport.width * scale * devicePixelRatio;
            canvas.height = viewport.height * scale * devicePixelRatio;
            canvas.style.width = `${viewport.width * scale}px`;
            canvas.style.height = `${viewport.height * scale}px`;

            context.scale(devicePixelRatio, devicePixelRatio);

            const renderContext = {
                canvasContext: context,
                viewport: page.getViewport({ scale }),
            };

            // setIsRenderInProgress(true);
            renderTaskRef.current = page.render(renderContext);

            await renderTaskRef.current.promise;
            renderTaskRef.current = null;
            // setIsRenderInProgress(false);

            if (initialPdfCanvasDimensions.width === 0) {
                setInitialPdfCanvasDimensions({
                    width: viewport.width * scale,
                    height: viewport.height * scale,
                });
            }


        } catch (error) {
            if (error.name !== 'RenderingCancelledException') {
                console.error('Error rendering page:', error);
            } else {
                console.log('Rendering was cancelled:', error);
            }
        }
    };

    // ==== 200% ==== //
    // const loadPdf = async () => {
    //     if (renderTaskRef.current) {
    //         try {
    //             await renderTaskRef.current.cancel();
    //             renderTaskRef.current = null;
    //         } catch (error) {
    //             return;
    //         }
    //     }
    //
    //     try {
    //         const pdf = await getDocument(samplePdf).promise;
    //         const page = await pdf.getPage(1);
    //         const viewport = page.getViewport({ scale: 1 });
    //
    //         const canvas = pdfCanvasRef.current;
    //         const context = canvas.getContext('2d');
    //         const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;
    //
    //         const devicePixelRatio = window.devicePixelRatio || 1;
    //         const isMobile = window.innerWidth <= 1000;
    //         const scale = isMobile ? (containerWidth / viewport.width) * 2 : containerWidth / viewport.width;
    //
    //         canvas.width = viewport.width * scale * devicePixelRatio;
    //         canvas.height = viewport.height * scale * devicePixelRatio;
    //         canvas.style.width = `${viewport.width * scale}px`;
    //         canvas.style.height = `${viewport.height * scale}px`;
    //
    //         context.scale(devicePixelRatio, devicePixelRatio);
    //
    //         const renderContext = {
    //             canvasContext: context,
    //             viewport: page.getViewport({ scale }),
    //         };
    //
    //         renderTaskRef.current = page.render(renderContext);
    //
    //         await renderTaskRef.current.promise;
    //         renderTaskRef.current = null;
    //
    //         if (initialPdfCanvasDimensions.width === 0) {
    //             setInitialPdfCanvasDimensions({
    //                 width: viewport.width * scale,
    //                 height: viewport.height * scale,
    //             });
    //         }
    //     } catch (error) {
    //         if (error.name !== 'RenderingCancelledException') {
    //             console.error('Error rendering page:', error);
    //         } else {
    //             console.log('Rendering was cancelled:', error);
    //         }
    //     }
    // };

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const updateCanvasSize = debounce(async () => {
        if (pdfCanvasRef.current && sigCanvasRef.current) {

            const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;
            const devicePixelRatio = window.devicePixelRatio || 1;
            const containerHeight = containerWidth * 1.414; // A4 aspect ratio
            const sigCanvas = sigCanvasRef.current.getCanvas();
            const tempCanvas = document.createElement('canvas');

            tempCanvas.width = sigCanvas.width;
            tempCanvas.height = sigCanvas.height;
            tempCanvas.getContext('2d').drawImage(sigCanvas, 0, 0);

            pdfCanvasRef.current.width = containerWidth * devicePixelRatio;
            pdfCanvasRef.current.height = containerHeight * devicePixelRatio;
            pdfCanvasRef.current.style.width = `${containerWidth}px`;
            pdfCanvasRef.current.style.height = `${containerHeight}px`;

            sigCanvas.width = containerWidth * devicePixelRatio;
            sigCanvas.height = containerHeight * devicePixelRatio;
            sigCanvas.style.width = `${containerWidth}px`;
            sigCanvas.style.height = `${containerHeight}px`;

            const sigContext = sigCanvas.getContext('2d');
            sigContext.scale(devicePixelRatio, devicePixelRatio);
            sigContext.drawImage(tempCanvas, 0, 0, containerWidth * devicePixelRatio, containerHeight * devicePixelRatio);

            annotations.forEach((annotation, index) => {
                const input = inputRefs.current[index];
                if (input) {
                    const newTop = `${annotation.y}%`;
                    const newLeft = `${annotation.x}%`;
                    input.style.top = newTop;
                    input.style.left = newLeft;
                }
            });

            updatePenWidths();
            await loadPdf();

            if (window.innerWidth > 1000) {
                setIsDrawingEnabled(true)
            } else {
                setIsDrawingEnabled(false)
            }
            // sigCanvasRef.current.off(); // Disable drawing
            // setIsDrawingEnabled(false)
            // setIsSigning(false);

        }
    }, 200);


    // ==== 200% ==== //
    // const updateCanvasSize = debounce(async () => {
    //     if (pdfCanvasRef.current && sigCanvasRef.current) {
    //         const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;
    //         const devicePixelRatio = window.devicePixelRatio || 1;
    //         const isMobile = window.innerWidth <= 1000;
    //         const containerHeight = isMobile ? containerWidth * 1.414 * 2 : containerWidth * 1.414;
    //
    //         const sigCanvas = sigCanvasRef.current.getCanvas();
    //         const tempCanvas = document.createElement('canvas');
    //
    //         tempCanvas.width = sigCanvas.width;
    //         tempCanvas.height = sigCanvas.height;
    //         tempCanvas.getContext('2d').drawImage(sigCanvas, 0, 0);
    //
    //         pdfCanvasRef.current.width = containerWidth * devicePixelRatio;
    //         pdfCanvasRef.current.height = containerHeight * devicePixelRatio;
    //         pdfCanvasRef.current.style.width = `${containerWidth}px`;
    //         pdfCanvasRef.current.style.height = `${containerHeight}px`;
    //
    //         sigCanvas.width = containerWidth * devicePixelRatio;
    //         sigCanvas.height = containerHeight * devicePixelRatio;
    //         sigCanvas.style.width = `${containerWidth}px`;
    //         sigCanvas.style.height = `${containerHeight}px`;
    //
    //         const sigContext = sigCanvas.getContext('2d');
    //         sigContext.scale(devicePixelRatio, devicePixelRatio);
    //         sigContext.drawImage(tempCanvas, 0, 0, containerWidth * devicePixelRatio, containerHeight * devicePixelRatio);
    //
    //         annotations.forEach((annotation, index) => {
    //             const input = inputRefs.current[index];
    //             if (input) {
    //                 const newTop = isMobile ? `${annotation.y * 2}%` : `${annotation.y}%`;
    //                 const newLeft = isMobile ? `${annotation.x * 2}%` : `${annotation.x}%`;
    //                 input.style.top = newTop;
    //                 input.style.left = newLeft;
    //             }
    //         });
    //
    //         updatePenWidths();
    //         await loadPdf();
    //
    //         if (window.innerWidth > 1000) {
    //             setIsDrawingEnabled(true);
    //         } else {
    //             setIsDrawingEnabled(false);
    //         }
    //     }
    // }, 200);

    useEffect(() => {
        // Initial update
        updateCanvasSize();

        // Add event listener to resize
        window.addEventListener('resize', updateCanvasSize);

        // Clean up function to remove the event listener
        return () => {
            if (renderTaskRef.current) {
                renderTaskRef.current.cancel();
            }
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, [samplePdf]); // Add samplePdf to the dependency array

    const clearSignature = () => {
        sigCanvasRef.current.clear();
        setSignature(null);
        setTimestamp(null);
    };

    const handleDateChange = (date, name) => {
        setDates({ ...dates, [name]: date });
    };


    const uploadFile = (file) => {
        const studentNumber = window.sessionStorage.getItem("studentId");
        const certCode = window.sessionStorage.getItem("currentCertificate");
        const moduleCode = window.sessionStorage.getItem("currentModuleCode");

        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("studentNumber", studentNumber);
            formData.append("moduleCode", moduleCode);
            formData.append("certCode", certCode);

            // console.log("Uploading file:", {
            //     fileName: file.name,
            //     studentNumber: studentNumber,
            //     moduleCode: moduleCode,
            //     certCode: certCode,
            //     originalFileName: file.name
            // });

            const req = new XMLHttpRequest();

            req.onload = () => {
                if (req.status >= 200 && req.status < 300) {
                    resolve(req.response);
                } else {
                    reject(`Failed to upload file. Status: ${req.status}`);
                }
            };

            req.onerror = () => {
                reject("Failed to upload file.");
            };

            req.open("POST", "https://api.trainingprofessionals.com.au/student/upload");
            req.send(formData);
        });
    };

    const saveSignature = async () => {
        const pdfCanvas = pdfCanvasRef.current;
        const signatureCanvas = sigCanvasRef.current.getCanvas();
        const scaleFactor = 1;
        const dpr = window.devicePixelRatio || 1;

        const combinedCanvas = document.createElement('canvas');
        combinedCanvas.width = pdfCanvas.width * scaleFactor;
        combinedCanvas.height = pdfCanvas.height * scaleFactor;
        const combinedContext = combinedCanvas.getContext('2d');
        combinedContext.scale(scaleFactor, scaleFactor);
        combinedContext.drawImage(pdfCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);
        combinedContext.drawImage(signatureCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);

        annotations.forEach((annotation, index) => {
            if (annotation.name === 'SignHere' || annotation.name === 'SignatureBox') return;

            const fontSize = calculateFontSize(pdfCanvas.height, dpr) * dpr;
            combinedContext.font = `${annotation.fontWeight} ${fontSize}px sans-serif`;
            combinedContext.fillStyle = annotation.color;

            const x = (annotation.x / 100) * pdfCanvas.width;
            const y = ((annotation.y / 100) * pdfCanvas.height) - 10;

            if (!annotation.input) {
                combinedContext.fillText(annotation.label, x, y);
            } else {
                let inputValue;
                if (annotation.input === 'date') {
                    inputValue = dates[annotation.name] ? dayjs(dates[annotation.name]).format('DD/MM/YYYY') : "";
                } else {
                    inputValue = inputRefs.current[index] ? inputRefs.current[index].value : "";
                }
                combinedContext.fillText(inputValue, x, y);
            }
        });

        // Convert the combined canvas to a data URL
        const imgData = combinedCanvas.toDataURL('image/png');

        // Create a new jsPDF instance and set the proper dimensions
        const pdf = new jsPDF('portrait', 'pt', [combinedCanvas.width, combinedCanvas.height]);
        pdf.addImage(imgData, 'PNG', 0, 0, combinedCanvas.width, combinedCanvas.height);

        const pdfBlob = pdf.output('blob');
        const file = new File([pdfBlob], 'signed_coversheet.pdf', { type: 'application/pdf' });

        try {
            const response = await uploadFile(file);
            // console.log('File uploaded successfully:', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // const saveSignature = async () => {
    //     const pdfCanvas = pdfCanvasRef.current;
    //     const signatureCanvas = sigCanvasRef.current.getCanvas();
    //     const scaleFactor = 1;
    //     const dpr = window.devicePixelRatio || 1;
    //
    //     const combinedCanvas = document.createElement('canvas');
    //     combinedCanvas.width = pdfCanvas.width * scaleFactor;
    //     combinedCanvas.height = pdfCanvas.height * scaleFactor;
    //     const combinedContext = combinedCanvas.getContext('2d');
    //     combinedContext.scale(scaleFactor, scaleFactor);
    //     combinedContext.drawImage(pdfCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);
    //     combinedContext.drawImage(signatureCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);
    //
    //     annotations.forEach((annotation, index) => {
    //         if (annotation.name === 'SignHere') return;
    //         if (annotation.name === 'SignatureBox') return;
    //
    //         const fontSize = calculateFontSize(pdfCanvasHeight, dpr) * dpr;
    //         combinedContext.font = `${annotation.fontWeight} ${fontSize}px sans-serif`;
    //         combinedContext.fillStyle = annotation.color;
    //
    //         const x = (annotation.x / 100) * pdfCanvas.width;
    //         const y = ((annotation.y / 100) * pdfCanvas.height) - 10;
    //
    //         if (!annotation.input) {
    //             combinedContext.fillText(annotation.label, x, y);
    //         } else {
    //             let inputValue;
    //             if (annotation.input === 'date') {
    //                 inputValue = dates[annotation.name] ? dayjs(dates[annotation.name]).format('DD/MM/YYYY') : "";
    //             } else {
    //                 inputValue = inputRefs.current[index] ? inputRefs.current[index].value : "";
    //             }
    //             combinedContext.fillText(inputValue, x, y);
    //         }
    //     });
    //
    //     const pdf = new jsPDF({
    //         orientation: 'portrait',
    //         unit: 'mm',
    //         format: 'a4'
    //     });
    //
    //     // Use JPEG and compress the image to reduce file size
    //     const imgData = combinedCanvas.toDataURL('image/jpeg', 0.7); // Adjust quality parameter as needed (0.0 to 1.0)
    //     pdf.addImage(imgData, 'JPEG', 0, 0, A4_WIDTH, A4_HEIGHT);
    //     pdf.save('signed_document.pdf');
    // };

    // const preventDefault = (e) => {
    //     e.preventDefault();
    // };

    const toggleDrawingCanvas = () => {
        setIsDrawingEnabled(!isDrawingEnabled);
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        zIndex: 3,
        display: isDrawingEnabled ? 'none' : 'block', // Show when drawing is disabled
        // display: window.innerWidth > 1000 ? 'none' : 'block',
    };

    const mobileButtonStyle = {
        position: 'fixed',
        top: '38vh',  // Use viewport height units
        left: '65vw',    // Use viewport width units
        width: '10vw',  // Use viewport width units for consistent sizing
        height: '5vw', // Use viewport width units for consistent sizing
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',  // Ensure text color contrasts with the background
        border: 'none',
        borderRadius: '50%',
        fontSize: '1vw',  // Use viewport width units for consistent text size
        textAlign: 'center',
        lineHeight: '1vw', // Ensure line height matches the button height
        cursor: 'pointer',
        zIndex: 1000,
        display: window.innerWidth > 1000 ? 'none' : 'block', // Show only on mobile
    };

    const containerStyle = {
        padding: '25px',
        margin: '25px',
        border: 'none',
        // borderRadius: '4px',
        backgroundColor: 'white', // Changed for better visibility
        width: '100%', // Ensure it takes full width of its parent
        height: 'auto', // Height is automatically adjusted to maintain the aspect ratio
        aspectRatio: '1 / 1.414', // Use aspect ratio to maintain A4 dimensions
        maxWidth: '1000px', // Maximum width
        maxHeight: '1414px', // Maximum height based on A4 dimensions
        position: 'relative',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' // Shadow for floating effect
    };

    const canvasStyle = {
        width: '100%',
        height: '100%', // This ensures the canvas always fills the container
        border: 'none',
        // borderRadius: '15px',
        position: 'absolute',
        top: 0,
        left: 0,
    };

    const buttonContainerStyle = {
        width: '100%', // Ensure it takes full width
        margin: 'auto',
        maxWidth: '1060px', // Maximum width
        padding: '10px 20px', // Padding for aesthetic spacing
        boxSizing: 'border-box', // Include padding in width calculation
        display: 'flex', // Use flex for inner spacing
        justifyContent: 'space-between', // Space between buttons
        zIndex: 1000, // Ensure it's on top of other content
    };

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>

            <div style={buttonContainerStyle}>
                <button className={'btn-primary'} style={buttonStyle} onClick={clearSignature}>Clear</button>
                <button className={'btn-primary'} style={buttonStyle} onClick={saveSignature}>Save</button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', width: '100%' }}>
                <div style={containerStyle}>
                    <canvas ref={pdfCanvasRef} style={{ ...canvasStyle, zIndex: 1 }} />
                    <SignatureCanvas
                        ref={sigCanvasRef}
                        penColor="black"
                        minWidth={calculatePenWidths(pdfCanvasHeight).minWidth}
                        maxWidth={calculatePenWidths(pdfCanvasHeight).maxWidth}
                        canvasProps={{
                            style: {
                                width: '100%',
                                height: `100%`,
                                border: 'none',
                                borderRadius: '15px',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 2
                            },
                        }}
                    />
                    <div style={overlayStyle} /> {/* Add the overlay element */}
                    {annotations.map((annotation, index) => {
                        const dpr = window.devicePixelRatio || 1;
                        const fontSize = `${calculateFontSize(pdfCanvasHeight, dpr)}px`; // Calculate font size and normalize by DPR
                        return (
                            <div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    top: `${annotation.y}%`,
                                    left: `${annotation.x}%`,
                                    transform: 'translateY(-100%)',
                                    zIndex: annotation.input ? 3 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    userSelect: 'none',
                                    fontSize, // Apply dynamic font size
                                    fontWeight: annotation.fontWeight,
                                    color: annotation.color
                                }}
                            >
                                {annotation.input ? (
                                    annotation.input === 'date' ? (
                                        <DatePicker
                                            selected={dates[annotation.name]}
                                            onChange={(date) => handleDateChange(date, annotation.name)}
                                            dateFormat="dd/MM/yyyy"
                                            dropdownMode="select"
                                            popperPlacement="top-start"
                                            popperContainer={CalendarContainer}
                                            placeholderText="Select Date"
                                            customInput={
                                                <input
                                                    type="text"
                                                    style={{
                                                        zIndex: 3,
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        fontSize,
                                                        fontWeight: annotation.fontWeight,
                                                        color: annotation.color,
                                                        outline: 'none',
                                                        cursor: 'pointer',
                                                        textAlign: 'start',
                                                    }}
                                                    ref={(el) => (inputRefs.current[index] = el)}
                                                />
                                            }
                                            wrapperClassName="custom-datepicker-wrapper"
                                            popperClassName="custom-datepicker-popper"
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder={annotation.label}
                                            style={{
                                                zIndex: 3,
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                fontSize, // Apply dynamic font size
                                                fontWeight: annotation.fontWeight,
                                                color: annotation.color,
                                                outline: 'none',
                                            }}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                        />
                                    )
                                ) : annotation.name === 'SignatureBox' ? (
                                    <div
                                        style={{
                                            width: `${(annotation.width / 100) * pdfCanvasWidth / dpr}px`,
                                            height: `${(annotation.height / 100) * pdfCanvasHeight / dpr}px`,
                                            zIndex: 1,
                                            backgroundColor: annotation.color,
                                            position: 'absolute'
                                        }}
                                    ></div>
                                ) : (
                                    <span
                                        style={{
                                            color: annotation.color,
                                            fontSize, // Apply dynamic font size
                                            fontWeight: annotation.fontWeight,
                                            zIndex: 1,
                                        }}
                                    >
                    {annotation.label}
                </span>
                                )}
                            </div>
                        );
                    })}

                    {signature && (
                        <div>
                            <h3>Saved Signature:</h3>
                            <img src={signature} alt="signature" style={{}} />
                            <p>Timestamp: {timestamp}</p>
                        </div>
                    )}
                </div>

                <button
                    // className="mobile-toggle-button"
                    style={mobileButtonStyle}
                    onClick={toggleDrawingCanvas}
                >
                    {isDrawingEnabled ? 'Stop Signing' : 'Click to Sign'}
                </button>

            </div>

        </div>

    );

};

export default SignaturePanel;
