import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConstLink } from "../ConstLink";
import CertCarousel from '../Components/CertCarousel';
import logError from "../Components/Logger";
import CustomAlert from "../Components/CustomAlert";  // error handler

export default function Study() {

    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [loading, setLoading] = useState(true);
    const [studentHistory, setStudentHistory] = useState(null);
    const navigate = useNavigate();
    const { isLoggedIn } = useContext(ConstLink);
    const studentID = window.sessionStorage.getItem("studentId");

    useEffect(() => {
        let interval = setInterval(() => {
            const fetchedHistory = window.sessionStorage.getItem('studentHistory');
            if (fetchedHistory !== null) {
                const history = JSON.parse(fetchedHistory);
                setStudentHistory(history.reverse());
                setLoading(false);
                clearInterval(interval);
            }
        }, 1000); // Reduced frequency to reduce load

        let timeout = setTimeout(() => {
            clearInterval(interval);
            if (!studentHistory) {
                setLoading(false);
                logError({
                    page: window.location.pathname,
                    userId: studentID,
                    errorDesc: 'No student information to display.'
                }).then(response => {
                    console.log('Server responded with:', response);
                }).catch(err => {
                    console.error('Error logging failed:', err);
                    setMsg('Failed to communicate with server');
                    setMsgCode(1);
                });
            }
        }, 15000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [studentID, studentHistory]); // Added studentHistory to dependencies if needed

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    return (
        <div>
            <CustomAlert msg={msg} msgCode={msgCode} />
            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>
                {loading ? (
                    <div className="spinner"></div>
                ) : (
                    studentHistory ? (
                        <CertCarousel items={studentHistory} />
                    ) : (
                        <div>No student history could be found</div>
                    )
                )}
            </div>
        </div>
    );
}
