import React, { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import logError from "../Components/Logger";
import CustomAlert from '../Components/CustomAlert';
import '../Styling/index.css';

// Put your API key here
builder.init('9992ee9d879a492ebbc6238da3ea846f');

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
    const isPreviewingInBuilder = useIsPreviewing();
    const [notFound, setNotFound] = useState(false);
    const [content, setContent] = useState(null);
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);

    try {
        // get the page content from Builder
        useEffect(() => {
            async function fetchContent() {
                const content = await builder
                    .get("page", {
                        url: window.location.pathname
                    })
                    .promise();

                setContent(content);
                setNotFound(!content);

                // set the document title
                if (content?.data.title) {
                    document.title = content.data.title
                }
            }

            fetchContent();
        }, [window.location.pathname]);

        // If no page is found, return
        // a 404 page from your code.
        // The following hypothetical
        // <FourOhFour> is placeholder.
        if (notFound && !isPreviewingInBuilder) {
            return (
                <CustomAlert msg={msg} msgCode={msgCode} />
            )
        }
    }

    catch(error) {

        logError({
            page: window.location.pathname,
            errorData: error.data,
            errorCode: error.errorCode,
            errorStack: error.stack,
            errorMsg: error.message,
            errorDesc: 'Failed to display page', // Detailed description of the error
        }).then(response => {
            console.log(response);
            setMsg(`Server authorisation failed. Event ID: ${response.insertedId}`); // Example of using the response
            setMsgCode(2);
        }).catch(err => {
            console.error('Error logging failed:', err);
            setMsg('Failed to communicate with server');
            setMsgCode(3);
        });
    }






    // return the page when found
    return (
        <>
            {/* Render the Builder page */}
            <BuilderComponent model="page" content={content} />
        </>
    );
}

// =============================== To Do =================================== //

// 9992ee9d879a492ebbc6238da3ea846f

// =============================== Bugs =================================== //