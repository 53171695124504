import {useState, useEffect, useContext} from 'react';
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Alert, AlertTitle } from '@mui/material'
import backgroundImage from "../Images/TPLogoHighRes.png";
import CustomSwitch from "../Components/CustomSwitch";
import logError from "../Components/Logger";  //  error handler

// import '../Styling/main.css';
// import '../Styling/social.css';
import '../Styling/index.css';
import {useLocation, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faWindows } from '@fortawesome/free-brands-svg-icons';
import CustomAlert from "../Components/CustomAlert";
import {ConstLink} from "../ConstLink";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import PasswordStrengthBar from 'react-password-strength-bar';



export default function Enrol() {




    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const responseData = document.getElementById("respData");
    const [msg, setMsg] = useState(window.sessionStorage.getItem('message'))
    const [msgCode, setMsgCode] = useState(0)
    const { isLoggedIn, setIsLoggedIn } = useContext(ConstLink);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    // const studentId = data.user_id;
    // const requestBody = JSON.stringify({ "studentID": studentId });

    const handleLogin = (data) => {
        console.log(data);
        window.sessionStorage.setItem("email", data.email);
        window.sessionStorage.setItem("username", data.username);
        window.sessionStorage.setItem("user_name", data.user_name);
        window.sessionStorage.setItem("user_id", data.user_id);
        window.sessionStorage.setItem("profile_id", data.profile_id);
        window.sessionStorage.setItem("timerSet", "no");
        window.sessionStorage.setItem("userLoggedIn", "true");
        window.sessionStorage.setItem("message", "You are signed in.");
        window.sessionStorage.setItem("timerStartTime", "");
        window.localStorage.setItem("timerStartTime", Date.now());
        setIsLoggedIn(true);
    }
    const [ipAddress, setIpAddress] = useState(''); //error handler from here

    const requestBody = JSON.stringify(inputs);
    const endpoint = 'https://localhost:3001/user/login';

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const location = useLocation(); //error handler


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        if (event.target.name === "password") {
            setPassword(event.target.value);
        } else if (event.target.name === "password2") {
            setConfirmPassword(event.target.value);
        }
    }

    const navigateTo = (address) => {
        navigate(address);
    };

    const handleFacebookLogin = () => {
        // Logic to handle Facebook login
    };

    const handleGoogleLogin = () => {
        // Logic to handle Google login
    };

    const handleMicrosoftLogin = () => {
        // Logic to handle Microsoft login
    };


    const handleSubmit = (event) => {

        event.preventDefault();

        if ( inputs.password === inputs.password2 ) {

            // console.log('Passwords match');

            const requestBody = JSON.stringify(inputs)

            fetch('', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: requestBody,
                credentials: 'include',
            })
                .then(response => response.json().then(data => {

                    console.log(response.status);

                    if ( response.status === 201 ) {
                        console.log('User created and signed in');
                        window.location.href = '/profile';
                        setMsg('User created and signed in successfully!')
                        handleLogin(data);
                        setMsgCode(4);
                    } else if ( response.status === 400 ) {
                        // console.log('Request body incomplete, both email and password are required');
                        setMsg('Please enter a valid username and password.');
                        setMsgCode(1);
                        logError({
                            ipAddress: ipAddress,
                            page: location.pathname,
                            data: requestBody,
                            line: response.status, // Contains the line where the error was thrown if available
                            errorDesc: 'The user did not provided both a user name and password', // Detailed description of the error
                        });
                    } else if ( response.status === 405 ) {
                        console.log('Password did not meet requirements');
                        setMsg('Your password does not meet the required security level. Please ensure that it' +
                            ' contains at least 8 characters, including at least one uppercase letter, one lowercase ' +
                            'letter, one number, and one special character.');
                        setMsgCode(2);
                        logError({
                            ipAddress: ipAddress,
                            page: location.pathname,
                            data: requestBody,
                            line: response.status, // Contains the line where the error was thrown if available
                            errorDesc: 'The user has not provided both a user name and password' , // Detailed description of the error
                        });
                    } else if ( response.status === 409 ) {
                        console.log('User already exists');
                        setMsg('User already exists');
                        setMsgCode(1);
                        logError({
                            ipAddress: ipAddress,
                            page: location.pathname,
                            data: requestBody,
                            line: response.status, // Contains the line where the error was thrown if available
                            errorDesc: 'The user has attempted to create an account when they already have one' , // Detailed description of the error
                        });
                    } else if ( response.status === 429 ) {
                        console.log('Too many requests');
                        setMsg('Too many requests');
                        alert('WOOOAH... Too many requests - Slow down Turbot!')
                        setMsgCode(2);
                        logError({
                            ipAddress: ipAddress,
                            page: location.pathname,
                            data: requestBody,
                            line: response.status, // Contains the line where the error was thrown if available
                            errorDesc: 'Possible Security breach - There are too many requests being made.' , // Detailed description of the error
                        });
                    } else {
                        console.log( 'O_o    Sumfins Funky');
                        setMsg('A network error has occurred. Please try again later!');
                        setMsgCode(3);
                        logError({
                            ipAddress: ipAddress,
                            page: location.pathname,
                            data: requestBody,
                            line: response.status, // Contains the line where the error was thrown if available
                            errorDesc: 'Server responded with an unknown error while trying to Enrol the Students Certificate', // Detailed description of the error
                        });
                    }

                }))

                .catch(error => error );

        } else {

            console.log('Passwords do not match');
            setMsg('The passwords you entered do not match');
            setMsgCode(3);

        }

    }

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            <div
                className="image-overlay"
                style={{
                    position: 'absolute',
                    top: 100,
                    right: 100,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    opacity: 0.3,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right center',
                    backgroundSize: 'auto 100%',
                }}
            ></div>

            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',

                }}
            >

                {/*<header style={{ color: "#988558", backgroundColor: "charcoal" }} className="App-header">*/}

                <form onSubmit={handleSubmit}>

                    <MDBContainer style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: 'transparent'
                    }}>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ height: "1000px" }}>

                            <h3 style={{textAlign: 'center', marginBottom: 25}}>This will enrol you in {}</h3>

                            <CustomSwitch />

                            <CustomAlert msg={msg} msgCode={msgCode} />

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>
                                    {/*<label visible={false} style={{ padding: "10px" }}>Email: </label>*/}
                                    <input
                                        type="email"
                                        name="email"
                                        className="inputBox"
                                        // style={{width: 500}}
                                        placeholder={"ENTER YOUR EMAIL ADDRESS"}
                                        title='This will be your Username.'
                                        onChange={handleChange}
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>
                                    {/*<label visible={false} style={{ padding: "10px" }}>Email: </label>*/}
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="inputBox"
                                        // style={{width: 500}}
                                        placeholder={"FIRST NAME"}
                                        onChange={handleChange}
                                        title='Please enter you first name.'
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>
                                    {/*<label visible={false} style={{ padding: "10px" }}>Email: </label>*/}
                                    <input
                                        type="text"
                                        name="lastName"
                                        className="inputBox"
                                        // style={{width: 500}}
                                        placeholder={"LAST NAME"}
                                        onChange={handleChange}
                                        title='Please enter your last name.'
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            {/* Date of Birth Input */}
                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>
                                    <input
                                        type="text"
                                        name="dateOfBirth"
                                        className="inputBox"
                                        placeholder="DATE OF BIRTH" // Custom placeholder
                                        onFocus={(e) => e.target.type = 'date'} // Change type to 'date' on focus
                                        // onBlur={(e) => e.target.type = 'text'} // Change type back to 'text' on blur
                                        title='Please enter your Date of Birth.'
                                        onChange={handleChange}
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            {/*<MDBRow>*/}
                            {/*    <MDBCol end style={{ padding: "10px", width: "100%"}}>*/}
                            {/*        /!*<label style={{ padding: "10px" }}>Password: </label>*!/*/}
                            {/*        <input*/}
                            {/*            type="password"*/}
                            {/*            name="password"*/}
                            {/*            className="inputBox"*/}
                            {/*            // style={{width: 300}}*/}
                            {/*            placeholder={"ENTER A PASSWORD"}*/}
                            {/*            required*/}
                            {/*            onChange={handleChange}*/}
                            {/*        />*/}
                            {/*    </MDBCol>*/}
                            {/*</MDBRow>*/}

                            {/*<MDBRow>*/}
                            {/*    <MDBCol end style={{ padding: "10px", width: "100%"}}>*/}
                            {/*        /!*<label style={{ padding: "10px" }}>Confirm Password: </label>*!/*/}
                            {/*        <input*/}
                            {/*            type="password"*/}
                            {/*            name="password2"*/}
                            {/*            className="inputBox"*/}
                            {/*            // style={{width: 300}}*/}
                            {/*            placeholder={"CONFIRM YOUR PASSWORD"}*/}
                            {/*            required*/}
                            {/*            onChange={handleChange}*/}
                            {/*        />*/}
                            {/*    </MDBCol>*/}
                            {/*</MDBRow>*/}

                            <div style={{ position: 'relative'}}>
                                <input
                                    type={showPassword1 ? 'text' : 'password'}
                                    name="password"
                                    className="inputBox"
                                    placeholder={"ENTER A PASSWORD"}
                                    onChange={handleChange}
                                    style={{ paddingRight: '40px' }} // Make room for the icon
                                    title='Please ensure you use a minimum of 8 characters including at least 1
                                    Uppercase letter, 1 number, and 1 special character. ie #Password1234.'
                                />
                                <FontAwesomeIcon
                                    icon={showPassword1 ? faEyeSlash : faEye}
                                    onClick={togglePasswordVisibility1}
                                    style={{
                                        position: 'absolute',
                                        top: 25,
                                        right: '15px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d' // Example color, adjust as needed
                                    }}
                                />
                                <PasswordStrengthBar password={password} />
                            </div>

                            <div style={{ position: 'relative', paddingTop: 10 }}>
                                <input
                                    type={showPassword2 ? 'text' : 'password'}
                                    className="inputBox"
                                    placeholder={"CONFIRM YOUR PASSWORD"}
                                    style={{ paddingRight: '40px', fontColor: '#886b26' }} // Make room for the icon
                                    name="password2"
                                    onChange={handleChange}

                                />
                                <FontAwesomeIcon
                                    icon={showPassword2 ? faEyeSlash : faEye}
                                    onClick={togglePasswordVisibility2}
                                    style={{
                                        position: 'absolute',
                                        top: '56%',
                                        right: '15px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d' // Example color, adjust as needed
                                    }}
                                />
                            </div>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px", textAlign: 'center', marginTop: 15 }}>
                                    <div className="customCheckbox">
                                        <input
                                            type="checkbox"
                                            id="termsAndConditions"
                                            name="termsAndConditions"
                                            style={{ transform: 'scale(2)' }}  // Scale the checkbox
                                            required
                                        />
                                        <label htmlFor="termsAndConditions" style={{ marginLeft: '20px' }}>
                                            I agree to the <a href="https://www.qrmezy.com/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a> of QRMezy.com
                                        </label>
                                    </div>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow>
                                <MDBCol center>
                                    <button style={{marginTop: 15}} className="btn-primary" type="submit">REGISTER</button>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol center>
                                    <button style={{marginTop: 15}} className="btn-primary" type="button" onClick={() => navigateTo('/')}>CANCEL </button>
                                </MDBCol>
                            </MDBRow>

                            {/*<div className="col-md-6 offset-md-3" style={{marginTop: 50}}>*/}
                            {/*    <button className="fb btn w-100" onClick={handleFacebookLogin}>*/}
                            {/*        <FontAwesomeIcon icon={faFacebook} /> Sign up with Facebook*/}
                            {/*    </button>*/}

                            {/*    <button className="google btn" onClick={handleGoogleLogin}>*/}
                            {/*        <FontAwesomeIcon icon={faGoogle} /> Sign up with Google+*/}
                            {/*    </button>*/}

                            {/*    <button className="microsoft btn" onClick={handleMicrosoftLogin}>*/}
                            {/*        <FontAwesomeIcon icon={faWindows} /> Sign up with Microsoft*/}
                            {/*    </button>*/}
                            {/*</div>*/}

                        </div>
                    </MDBContainer>



                </form>

                {/*</header>*/}

            </div>

        </div>
    );

}