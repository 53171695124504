import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
// import { useNavigate, useHistory } from 'react-router-dom';
import { ConstLink } from "ConstLink";
import { Document, Page, pdfjs } from "react-pdf";
import Bluey from "Components/Bluey";
// import { useLocation } from 'react-router-dom';
import { useLocation, useNavigation } from 'react-router-dom';
// import { useNavigate, useLocation, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import List, { ItemDragging } from "devextreme-react/list";
import SignaturePanel from "Components/SignaturePanel";
import CustomAlert from "../../Components/CustomAlert";
import {GiGameConsole} from "react-icons/gi";
import dayjs from 'dayjs';



export default function Assessment() {

    // Receives data from previous page (Will be lost on refresh)
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state ? location.state.jsonObject : null;
    const { setQuickMenu, isLoggedIn, setStepButtons } = useContext(ConstLink);
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    const [assessmentData, setAssessmentData] = useState([]);
    const [assessmentCount, setAssessmentCount] = useState(null);
    const [assessmentLength, setAssessmentLength] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionNumFix , setQuestionNumFix] = useState(2);
    const [completed, setCompleted ] = useState(false);
    const [module, setModule] = useState(data.modId); // <================================ Set the module here
    const [studentId, setStudenId] = useState(window.sessionStorage.getItem("studentId"));
    const [checkedBoxes, setCheckedBoxes] = useState(Array(20).fill(null));
    const [isValid, setIsValid] = useState(true);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [loading, setLoading] = useState(false);
    const init = useRef(false);
    const [isBlocking, setIsBlocking] = React.useState(false);
    const [assessmentStatus, setAssessmentStatus] = useState(null);
    const [showCoverSheet, setShowCoverSheet] = useState(true);
    // const [displayedQuestionNum, setDisplayedQuestionNum] = useState(data.questionNum - questionNumFix);

    const annotations = [
        {
            name: 'SignHere',
            x: 91,
            y: 41,
            label: '← Sign here',
            color: 'red',
            fontSize: '40px',
            fontWeight: 'bold',
            // width: 11.5, // Width of the yellow square as a percentage of the page width
            // height: 1, // Height of the yellow square as a percentage of the page height
            // boxX: 425, // x-coordinate for the yellow box as a percentage of the page width
            // boxY: 40, // y-coordinate for the yellow box as a percentage of the page height
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'SignatureBox',
            x: 54,
            y: 38,
            // label: 'Sign here →',
            color: 'rgba(255, 255, 0, 0.3)',
            // fontSize: '40px',
            // fontWeight: 'bold',
            width: 25, // Width of the yellow square as a percentage of the page width
            height: 3, // Height of the yellow square as a percentage of the page height
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'ModCode',
            x: 11,
            y: 30,
            label: window.sessionStorage.getItem("currentModuleCode"),
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'ModName',
            x: 35,
            y: 30,
            label: window.sessionStorage.getItem("currentModuleName"),
            color: 'Black',
            fontSize: '24px',
            fontWeight: 'bold',
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'DOB',
            x: 35, // percentage
            y: 39, // percentage
            label: '07/05/1984',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'Date',
            x: 79.5, // percentage
            y: 39, // percentage
            // label: dayjs().format('DD-MM-YYYY'),
            input: 'date', // Indicate this is a date input
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'Name',
            x: 11, // percentage
            y: 39, // percentage
            label: sessionStorage.getItem('user_name'),
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },

        // {
        //     name: 'Outcome',
        //     x: 880,
        //     y: 235,
        //     label: '✓', // Added tick to the label
        //     color: 'green',
        //     fontSize: '40px',
        //     fontWeight: 'bold',
        //     timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        // },
        {
            name: 'TrainerName',
            x: 13,
            y: 51.8,
            label: 'Enter trainers name',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            input: 'text', // Indicate this is a text input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'ContentExpert',
            x: 13,
            y: 61,
            label: 'Enter content expert',
            color: 'Black',
            fontSize: '13px',
            fontWeight: 'bold',
            input: 'text', // Indicate this is a text input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'EmployerName',
            x: 13,
            y: 76.5,
            label: 'Enter employers name...',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            input: 'text', // Indicate this is a text input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'DateInput2',
            x: 76,
            y: 51.8,
            // label: 'Enter date',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            input: 'date', // Indicate this is a date input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'DateInput1',
            x: 76,
            y: 61,
            // label: 'Enter date',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            input: 'date', // Indicate this is a date input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'DateInput3',
            x: 76,
            y: 76.5,
            // label: 'Enter date',
            color: 'Black',
            fontSize: '14px',
            fontWeight: 'bold',
            input: 'date', // Indicate this is a date input
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss')
        },
        // Add more annotations as needed
    ];

    useEffect(() => {

        // console.log('Data:', data);

        // fetchData(module); // This is why its firing at the api twice <===================================================================================88888
        if (!init.current && module) {  // Only fetch data if the component is mounted
            fetchData(module);
            fetchStatus(module, window.sessionStorage.getItem("studentId"));
            init.current = true;  // Ensure fetch is only called once
        }

        // Prevent the user from accidentally leaving the page with unsaved changes
        // const handleBeforeUnload = (event) => {
        //     const message = "Are you sure you want to leave? All unsaved changes will be lost.";
        //     event.returnValue = message; // Standard for most browsers
        //     return message; // For some older browsers
        // };

        // window.addEventListener('beforeunload', handleBeforeUnload);
        //
        // window.onbeforeunload = function() {
        //     return "Are you sure you want to navigate away?";
        // }
        //
        // // Cleanup the event listener when the component unmounts
        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        // };


    }, []);


    const handleInputChange = (event) => {
        setIsBlocking(event.target.value.length > 0);
    };

    const handleCheckboxChange = (index, value) => {
        const newCheckedBoxes = [...checkedBoxes];
        newCheckedBoxes[index] = value;
        setCheckedBoxes(newCheckedBoxes);
    };

    const handleRadioChange = (index) => {
        setSelectedRadio(index);
    };


    async function fetchData(moduleID) {
        setLoading(true);  // Set loading to true when the fetch starts
        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/student/assess_questions', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ 'moduleID': moduleID })
            });

            const apiData = await response.json(); // Wait for the JSON data

            let questionCounter = 0; // Initialize a counter for the question numbers
            // Filter the data and reassign question numbers sequentially
            const filteredData = Object.values(apiData.data || {}).filter(item =>
                item.section === 1 &&
                item.assessmentNumber === 1 &&
                ![4, 9, 10].includes(item.QType)
            ).map(item => ({
                ...item,
                questionNum: questionCounter++  // Assign new sequential question numbers
            }));


            // Handle response based on the status code
            switch (response.status) {
                case 200:
                    setAssessmentCount(apiData.count);
                    setAssessmentData(filteredData);
                    setAssessmentLength(filteredData.length);
                    console.log("Filtered Data with Reassigned Question Numbers:", filteredData);
                    setMsgCode(4);
                    break;
                case 201:
                    setMsgCode(4);
                    break;
                case 400:
                    setMsgCode(1);
                    break;
                case 401:
                    console.log('Unauthorized access');
                    setMsg('Unauthorized');
                    setMsgCode(1);
                    break;
                case 429:
                    console.log('Too many requests');
                    alert('Too many requests, please try again later');
                    setMsg('Rate limit exceeded');
                    setMsgCode(2);
                    break;
                default:
                    console.log('Unexpected error');
                    break;
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setMsgCode(0);
        } finally {
            setLoading(false);  // Set loading to false when fetch completes
        }
    }

    async function fetchStatus(moduleID, studentID) {

        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/student/assessmentStatus', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ 'moduleID': String(moduleID), 'studentID': studentID })
            });

            const apiData = await response.json(); // Wait for the JSON data

            // Handle response based on the status code
            switch (response.status) {
                case 200:
                    setAssessmentStatus(apiData.status);
                    // console.log('Is signed:', apiData[0].isSigned_Student);
                    if (apiData[0].isSigned_Student === 1) {
                        setShowCoverSheet(false);
                    }
                    console.log("Retrieved assessment status:", apiData);
                    setMsgCode(4);
                    break;
                case 201:
                    setAssessmentStatus('started');
                    console.log("New status entry created:", apiData);
                    setMsgCode(4);
                    break;
                case 400:
                    setMsgCode(1);
                    break;
                case 401:
                    console.log('Unauthorized access');
                    setMsg('Unauthorized');
                    setMsgCode(1);
                    break;
                case 429:
                    console.log('Too many requests');
                    alert('Too many requests, please try again later');
                    setMsg('Rate limit exceeded');
                    setMsgCode(2);
                    break;
                default:
                    console.log('Unexpected error');
                    break;
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setMsgCode(0);
        } finally {
            setLoading(false);  // Set loading to false when fetch completes
        }
    }


    function formatString(str) {
        if (!str) return str;

        let formattedStr = str;


        // Handle the special case for "(Fill in the blanks) - "
        const fillInTheBlanksIndex = formattedStr.indexOf('(Fill in the blanks) - ');
        if (fillInTheBlanksIndex !== -1) {
            const beforeBlanks = formattedStr.substring(0, fillInTheBlanksIndex + '(Fill in the blanks) - '.length);
            const afterBlanks = formattedStr.substring(fillInTheBlanksIndex + '(Fill in the blanks) - '.length).replace(/-/g, ' '); // Replace '-' with ' '
            formattedStr = `${beforeBlanks}\n${afterBlanks}`;
        }

        // Add a newline character after every colon
        formattedStr = formattedStr.replace(/:/g, ':\n');

        // Add a newline character after every semi-colon
        formattedStr = formattedStr.replace(/;/g, ';\n');

        // Replace <li> and </li> with line breaks
        formattedStr = formattedStr.replace(/<\/?li>/g, '\n');

        // Replace &apos; with regular apostrophe
        formattedStr = formattedStr.replace(/&apos;/g, "'");

        // Replace ~number with a dash
        formattedStr = formattedStr.replace(/~\d+/g, '-');

        // Remove <text:s/>
        formattedStr = formattedStr.replace(/<text:s\/>/g, '');

        // Remove <br>
        // formattedStr = formattedStr.replace(/<br>/g, ' ');
        formattedStr = formattedStr.replace(/<br>/g, '\n');

        // Existing logic for handling #. pattern
        formattedStr = formattedStr.split('<br>').map(paragraph => {
            // Check if the paragraph contains #. pattern
            if (paragraph.includes('#.')) {
                const parts = paragraph.split('#.');
                const intro = parts.shift(); // text before the first #.
                const bullets = parts.map(item => '• ' + item.trim()).join('\n');
                return `${intro}\n${bullets}`;
            }
            return paragraph;
        }).join('\n');

        // // Handle the special case for "(Fill in the blanks) - "
        // const fillInTheBlanksIndex = formattedStr.indexOf('(Fill in the blanks) - ');
        // if (fillInTheBlanksIndex !== -1) {
        //     const beforeBlanks = formattedStr.substring(0, fillInTheBlanksIndex + '(Fill in the blanks) - '.length);
        //     const afterBlanks = formattedStr.substring(fillInTheBlanksIndex + '(Fill in the blanks) - '.length).replace(/-/g, ' '); // Replace '-' with ' '
        //     formattedStr = `${beforeBlanks}\n<strong>${afterBlanks}</strong>`;
        // }

        return formattedStr;
    }

    const splitQuestion = (text) => {
        const segments = [];
        let lastIndex = 0;

        const regex = /(\S*?)\[([^\]]+)\](\S*?)(?=\s|$)/g; // Matches word, gap, and following word
        let match;

        while ((match = regex.exec(text)) !== null) {
            // Add any text that precedes the match
            if (lastIndex !== match.index) {
                segments.push({ type: "text", content: text.substring(lastIndex, match.index) });
            }
            // Combine prefix, gap, and suffix into a single segment
            segments.push({ type: "word_with_gap", prefix: match[1], gap: match[2], suffix: match[3] });

            lastIndex = match.index + match[0].length;
        }

        // Add any remaining text that follows the last match
        if (lastIndex < text.length) {
            segments.push({ type: "text", content: text.substring(lastIndex) });
        }

        return segments;
    };

    function numberToWord(number) {
        const words = [
            "first", "second", "third", "fourth", "fifth",
            "sixth", "seventh", "eighth", "ninth", "tenth",
            "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth",
            "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"
        ];

        return words[number - 1] || "";
    }


    const handleNextQuestion = () => {

        let incrementQuestionNum = questionNumFix;
        let incrementValue = 1; // default increment

        // console.log('Current question index:', currentQuestionIndex);

        if (assessmentData[currentQuestionIndex]?.QType === -1 &&
            assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
            assessmentData[currentQuestionIndex + 2]?.QType === 14) {
            incrementValue = 3; // skip the next 2 questions
            incrementQuestionNum = questionNumFix;
        } else if (assessmentData[currentQuestionIndex]?.QType === -1 &&
            assessmentData[currentQuestionIndex + 1]?.QType === 13) {
            incrementValue = 2;
            incrementQuestionNum = questionNumFix - 1;
        } else if (assessmentData[currentQuestionIndex]?.QType === -1 &&
            assessmentData[currentQuestionIndex + 1]?.QType === 14) {
            incrementValue = 2;
            incrementQuestionNum = questionNumFix - 1;
        }

        // Ensure we don't go beyond the assessmentData length
        while (currentQuestionIndex + incrementValue < assessmentData.length &&
        [4, 9, 10].includes(assessmentData[currentQuestionIndex + incrementValue]?.QType)) {
            incrementQuestionNum++;
            incrementValue++;
        }

        if (currentQuestionIndex + incrementValue >= assessmentLength) {
            console.log('Reached the end!');
            setCompleted(true);
            return;
        }

        setQuestionNumFix(incrementQuestionNum);
        setCurrentQuestionIndex(prevIndex => prevIndex + incrementValue);
        setCheckedBoxes(Array(20).fill(null)); // Reset checkboxes

        // console.log('Current question index:', currentQuestionIndex);

    };


    function PageComponent({ data, onNext, currentQuestionIndex, totalQuestions }) {

        const handleFormSubmit = (e) => {
            e.preventDefault();  // Prevent default form submission behavior

            const hasCheckboxes = Array.from({ length: 20 }).some((_, i) => data[`c${i + 1}`]);
            const hasSelectedRadio = selectedRadio !== null; // Assuming selectedRadio is defined somewhere
            const hasTextInputs = Array.from({ length: 20 }).some((_, i) => typeof data[`c${i + 1}`] === 'string' && data[`c${i + 1}`].trim() !== "");
            const formData = new FormData(e.target); // e.target is your form element

            // Convert FormData to a plain object
            // const formDataObj = {};
            // formData.forEach((value, key) => {
            //     formDataObj[key] = value;
            // });

            // Convert FormData to a plain object and handle dual-input concatenation
            const formDataObj = {};

            formData.forEach((value, key) => {
                if (key.includes('part1') || key.includes('part2')) {
                    // If part of a split input, concatenate appropriately
                    const baseKey = key.slice(0, -5); // Remove 'part1' or 'part2' to get the base key
                    formDataObj[baseKey] = formDataObj[baseKey] ? `${formDataObj[baseKey]}|${value}` : value;
                } else {
                    // Handle regular inputs
                    formDataObj[key] = value;
                }
            });

            // Specifically handle QType 5 order, ensuring not to add empty fields
            if (data.QType === 5) {
                qTypeFiveOrder.forEach((item, index) => {
                    if (item.text.trim() !== "") {  // Only add if text is not empty
                        formDataObj[`a${index + 1}`] = item.text;
                    }
                });

                // Clear remaining 'c' keys if less than 20 items
                for (let i = qTypeFiveOrder.length + 1; i <= 20; i++) {
                    if (formDataObj[`a${i}`]) {
                        delete formDataObj[`a${i}`];  // Remove key from object if it exists
                    }
                }
            }

            const shouldSubmit = (hasCheckboxes && !checkedBoxes.every((isChecked) => !isChecked)) || hasSelectedRadio || hasTextInputs;

            if (!shouldSubmit && hasCheckboxes && checkedBoxes.every((isChecked) => !isChecked)) {
                console.log('Has checkboxes, none checked');
                setIsValid(false);
                return;
            }

            // console.log('Has no checkboxes or radio buttons or at least one checked');
            // onNext();  // Call onNext immediately when the form is submitted

            // Then make a fetch call or some other API call to post the answers
            fetch('https://api.trainingprofessionals.com.au/student/student_answer', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ postedData: formDataObj }) // Send the formDataObj as postedData
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('API Response:', data);
                    if (data.nextUnanswered) {

                        const nextUnansweredIndex = data.nextUnanswered;
                        let incrementQuestionNum = 0;
                        let currentIdx = 0;
                        setQuestionNumFix(0);

                        // console.log('Next Unanswered Index:', nextUnansweredIndex);
                        // console.log('Initial Current Index:', currentIdx);
                        // console.log('Initial Increment Question Number:', incrementQuestionNum);
                        // console.log('questionNumFix:', questionNumFix);

                        while (currentIdx < (nextUnansweredIndex + questionNumFix)) {

                            let incrementValue = 1; // default increment

                            if (assessmentData[currentIdx]?.QType === -1 &&
                                assessmentData[currentIdx + 1]?.QType === 13 &&
                                assessmentData[currentIdx + 2]?.QType === 14) {
                                // console.log('This is evaluating as true');
                                incrementValue = 3; // skip the next 2 questions
                                incrementQuestionNum = questionNumFix;
                            } else if (assessmentData[currentIdx]?.QType === -1 &&
                                assessmentData[currentIdx + 1]?.QType === 13) {
                                incrementValue = 2;
                                incrementQuestionNum = questionNumFix - 1;
                            } else if (assessmentData[currentIdx]?.QType === -1 &&
                                assessmentData[currentIdx + 1]?.QType === 14) {
                                incrementValue = 2;
                                incrementQuestionNum = questionNumFix - 1;
                            }


                            // console.log(`Current Index: ${currentIdx}, Increment Value: ${incrementValue}, Increment Question Number: ${incrementQuestionNum}`);

                            // Assuming shouldIncrementValue function checks if the question type at the current index should cause a skip
                            // if ([4, 9, 10].includes(assessmentData[currentIdx]?.QType)) {
                            //     console.log('Skipping index:', currentIdx, 'due to question type:', assessmentData[currentIdx]?.QType);
                            //     currentIdx++;  // Increment to skip this question
                            //     incrementQuestionNum++;  // Adjust the question number accordingly
                            // }

                            if (currentIdx + incrementValue >= assessmentData.length) {
                                // console.log('Reached the end! Go do sum other stuff!');
                                setCompleted(true);
                                return;
                            }

                            // console.log('Current Index Before:', currentIdx);
                            // console.log('Increment Value:', incrementValue);
                            currentIdx += incrementValue;
                            // console.log('Current Index After:', currentIdx);
                        }

                        setQuestionNumFix(incrementQuestionNum);
                        setCurrentQuestionIndex(currentIdx);
                        // console.log('Current question index:', currentQuestionIndex);

                    } else {
                        handleNextQuestion();
                    }
                })
                .catch(error => {
                    // Log Error
                    console.error("Error submitting answers:", error);
                });

            setIsValid(true);
            setSelectedRadio(null);
        };

        // function convertToAnswers(originalObject) {
        //     console.log('Original Object:', originalObject);
        //     const steps = [];
        //     for (let i = 1; i <= 5; i++) {
        //         const key = `c${i}`;
        //         if (originalObject[key]) {
        //             steps.push({ id: i, text: originalObject[key] });
        //         }
        //     }
        //     console.log('Converted Steps:', steps);
        //     return steps;
        // }
        function convertToAnswers(originalObject) {
            // console.log('Original Object:', originalObject);
            const steps = [];
            // Loop up to 20 or until a null/undefined entry is found
            for (let i = 1; i <= 20; i++) {
                const key = `c${i}`;
                if (originalObject[key] === null || originalObject[key] === undefined) {
                    break; // Exit the loop early if the current step is null or undefined
                }
                if (originalObject[key]) { // Still check if the key exists and is not empty
                    steps.push({ id: i, text: originalObject[key] });
                }
            }
            // console.log('Converted Steps:', steps);
            return steps;
        }

        // Uses the above function to extract the initial order of answers
        // const [qTypeFiveOrder, setQtypeFiveOrder] = useState(convertToAnswers(data))
        const [qTypeFiveOrder, setQtypeFiveOrder] = useState(() => convertToAnswers(data)); // Using initializer function to avoid recalculations on re-renders

        let buttonText = "Next Question";  // default button text

        // First question (title/intro page)
        if (currentQuestionIndex === 0) {
            buttonText = "Start";
        }

        // Last question
        else if (completed) {
            buttonText = "Finish";
        }

        const adjustTextareaHeight = (event) => {
            const textarea = event.target;
            textarea.style.height = "auto"; // Reset height to auto
            textarea.style.height = textarea.scrollHeight + "px"; // Set the height to scrollHeight
        };

        const handleReorder = (e) => {
            const updatedOrder = [...qTypeFiveOrder];
            const [removed] = updatedOrder.splice(e.fromIndex, 1);
            updatedOrder.splice(e.toIndex, 0, removed);
            setQtypeFiveOrder(updatedOrder);
            // console.log(updatedOrder);
        };

        const isTrueOrFalse = (c1, c2) => {
            const lowerC1 = c1.toLowerCase().trim();
            const lowerC2 = c2.toLowerCase().trim();
            return (lowerC1 === "true." && lowerC2 === "false.") || (lowerC1 === "false." && lowerC2 === "true.");
        };

        const isYesOrNo = (c1, c2) => {
            const lowerC1 = c1.toLowerCase().trim();
            const lowerC2 = c2.toLowerCase().trim();
            return (lowerC1 === "yes" && lowerC2 === "no") || (lowerC1 === "no" && lowerC2 === "yes");
        };

        const renderQType13 = () => {
            if (assessmentData[currentQuestionIndex + 1]?.QType === 13) {
                return (
                    <div style={{ padding: '15px', textAlign: 'left' }}>
                        <h2 style={{ paddingBottom: 15 }}><strong>Information on Assessment Task</strong></h2>
                        <h3 style={{ whiteSpace: 'pre-line' }}>
                            {formatString(assessmentData[currentQuestionIndex + 1].question)}
                        </h3>
                    </div>
                );
            }
            return null; // Or some fallback component
        };

        const renderQType14 = () => {
            if (assessmentData[currentQuestionIndex + 1]?.QType === 14) {
                return (
                    <div style={{ padding: '15px', textAlign: 'left' }}>
                        <h3 style={{ whiteSpace: 'pre-line' }}>
                            {formatString(assessmentData[currentQuestionIndex + 2].question)} {/* Not +2 */}
                        </h3>
                    </div>
                );
            }
            return null; // Or some fallback component
        };


        return (
            <div>

                <form onSubmit={handleFormSubmit} id={"form"} autocomplete={"off"}>

                    {data.QType === -1 && (
                        <div className="intro-page">

                            <h1><strong>Assessment Task {data.section}</strong></h1>
                            <div style={{ margin: '40px 0' }}>
                                <h2 style={{ textAlign: 'left' }}>{formatString(data.question)}</h2>
                            </div>

                            {/* Check if the next QType is 13 and the one after is 14 */}
                            {assessmentData[currentQuestionIndex + 1]?.QType === 13 &&
                                assessmentData[currentQuestionIndex + 2]?.QType === 14 ? (
                                    <div>
                                        <div style={{ padding: '15px', textAlign: 'left'  }}>
                                            <h2 style={{paddingBottom: 15}}><strong>Information on Assessment Task</strong></h2>
                                            <h3 style={{ whiteSpace: 'pre-line' }}>
                                                {formatString(assessmentData[currentQuestionIndex + 1].question)}
                                            </h3>
                                        </div>

                                        <div style={{ padding: '15px', textAlign: 'left'  }}>
                                            <h3 style={{ whiteSpace: 'pre-line' }}>
                                                {formatString(assessmentData[currentQuestionIndex + 2].question)}
                                            </h3>
                                        </div>
                                    </div>

                                ) : (

                                    <div>
                                        {renderQType13()}
                                        {renderQType14()}
                                    </div>

                                )}

                            </div>


                    )}

                    {data.QType === 0 && (
                        <div className="free-range-answer">

                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>
                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text" >{formatString(data.question)}</h2>
                            </div>

                            <textarea required
                                className="textarea-answer"
                                name={"a1"}
                                rows={data.evidence}
                                placeholder="Type your answer here..."
                                // onChange={(e) => handleInputChange("freeRangeAnswer", e.target.value)}
                                onInput={adjustTextareaHeight}
                            />

                        </div>
                    )}


                    {data.QType === 1 && (
                        <div className="question">

                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>

                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>

                            {/* Check if it's a Yes/No question */}
                            {isYesOrNo(data.c1, data.c2) ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                        <input required
                                            type="radio"
                                            className="radio-as-checkbox"
                                            id="trueOption"
                                            name={`a1`}
                                            value="Yes"
                                            // onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                        />
                                        <label htmlFor="trueOption">{data.c1}</label>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                                        <input required
                                            type="radio"
                                            className="radio-as-checkbox"
                                            id="falseOption"
                                            name={`a2`}
                                            value="No"
                                            // onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                        />
                                        <label htmlFor="falseOption">{data.c2}</label>



                                    </div>
                                </div>
                            ) : (
                                // Multiple choices question
                                <div>
                                    {Array.from({ length: 20 }).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox"
                                                        id={`choice${i + 1}`}
                                                        name={`a${i + 1}`}
                                                        value="1"
                                                        style={{ }}
                                                        // onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    )}

                    {data.QType === 2 && (
                        <div className="question">

                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>
                            <div style={{ margin: '40px 0' }}>
                                <h2 className="justify-text">{formatString(data.question)}</h2>
                            </div>

                            {isTrueOrFalse(data.c1, data.c2) ? (

                                [data.c1, data.c2].map((choice, i) => { // Only iterate over true and false
                                    if (choice) {
                                        return (
                                            <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                <input
                                                    type="radio" // Keep it as radio
                                                    className="radio-as-checkbox"
                                                    id={`choice${i + 1}`}
                                                    name={`a${i + 1}`}
                                                    value={`1`}
                                                    checked={selectedRadio === i} // Use the selectedRadio logic
                                                    onChange={() => handleRadioChange(i)} // Same change handler as before
                                                />
                                                <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            ) : data.c1.includes("____________") ? (
                                    // Multi answer question with possible dual-input
                                    <div>
                                        {Array.from({ length: 20 }).map((_, i) => {
                                            const choice = data[`c${i + 1}`];
                                            if (choice) {
                                                const parts = choice.split("~3");
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '0px', width: '100%' }}>
                                                        <label style={{ marginRight: '10px', fontSize: 30, marginBottom: 15 }}>{i + 1}</label>

                                                        {/* Check if this is a dual-input choice */}
                                                        {parts.length > 1 ? (
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }}>
                                                                <input required
                                                                       type="text"
                                                                       name={`a${i + 1}part1`}
                                                                       className="multi-answer"
                                                                       placeholder="Part 1..."
                                                                       style={{ flex: 1 }}
                                                                />
                                                                <span style={{ margin: '0 5px', alignSelf: 'center' }}></span>
                                                                <input required
                                                                       type="text"
                                                                       name={`a${i + 1}part2`}
                                                                       className="multi-answer"
                                                                       placeholder="Part 2..."
                                                                       style={{ flex: 1 }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <input required
                                                                type="text"
                                                                name={`a${i + 1}`}
                                                                className="multi-answer"
                                                                placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                                                style={{ width: '100%' }}

                                                                // required
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                )
                                : (
                                // Multiple choices question
                                <div>

                                    {Array.from({ length: 20 }).map((_, i) => {
                                        const choice = data[`c${i + 1}`];
                                        if (choice) {
                                            return (
                                                <div key={i} style={{ display: 'flex', alignItems: 'start', marginBottom: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="radio-as-checkbox"
                                                        id={`choice${i + 1}`}
                                                        name={`a${i + 1}`}
                                                        value={`1`}
                                                        checked={checkedBoxes[i]}
                                                        onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                                    />
                                                    <label htmlFor={`choice${i + 1}`}>{formatString(choice)}</label>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    )}

                    {data.QType === 5 && (
                        <div className="sequencing-question">
                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>
                            <div className="justify-text" style={{ margin: '40px 0' }}>

                                <div dangerouslySetInnerHTML={{ __html: data.question }}></div>
                                <div className="widget-container" style={{marginTop: 25}}>

                                    <List items={qTypeFiveOrder}
                                          selectionMode="none"
                                          keyExpr="id"
                                          // disabled={true}
                                          focusedRowEnabled={false}
                                          activeStateEnabled={false}
                                          focusStateEnabled={false}
                                          repaintChangesOnly={false}>

                                        <ItemDragging
                                            draggable={true}
                                            onReorder={handleReorder}
                                            selectionMode="none"
                                            focusedRowEnabled={false}
                                            activeStateEnabled={false}
                                            allowReordering={true}
                                            repaintChangesOnly={false}
                                            group="tasks"
                                            data="doingTasks"
                                            // onDragStart={onDragStart}
                                            // onAdd={onAdd}
                                            // onRemove={onRemove}
                                        />
                                    </List>

                                    {/*<input type="hidden" name="draggedItemsOrder" id="draggedItemsOrder" />*/}

                                </div>
                            </div>
                        </div>
                    )}

                    {data.QType === 6 && (
                        <div className="fill-in-the-gaps">
                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>
                            <div className="justify-text" style={{ margin: '40px 0' }}> {/* Adjusting bottom and left margins */}
                                <div dangerouslySetInnerHTML={{ __html: data.question }} style={{ marginBottom: '50px' }} /> {/* Gap between question and answers */}

                                {Array.from({ length: 20 }, (_, index) => `c${index + 1}`).map((field, index) => {
                                    const value = data[field];
                                    return value ? (
                                        <div key={index} style={{marginLeft: 40}}>
                                            <strong>{value[0]}</strong>
                                            <input required
                                                type="text"
                                                name={`a${index + 1}`}
                                                className="gap-input"
                                                placeholder="..."
                                                style={{width: `${(value.length - 1) * 20}px`}} // Adjust width based on content length minus the first character
                                            />
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>
                    )}

                    {data.QType === 7 && (
                        <div className="fill-in-the-gaps">

                            <h1 style={{width: '100%'}}><strong>Question {data.questionNum - questionNumFix}
                                {/*<em> of </em>*/}
                                <span style={{ fontSize: 32, fontWeight: 'normal', color: '#000' }}><em> of  </em></span>
                                {totalQuestions}</strong>
                            </h1>

                            <div className="justify-text" style={{ marginTop: 25 }}>
                                {(() => {
                                    let inputCounter = 1;
                                    let boldText = false;
                                    return formatString(data.question).split('\n').map((line, index) => (
                                        <p key={index}>
                                            {splitQuestion(line).map((segment, innerIndex) => {
                                                if (segment.type === "text") {
                                                    if (segment.content.includes('(Fill in the blanks) - ')) {
                                                        const parts = segment.content.split('(Fill in the blanks) - ');
                                                        boldText = true; // Start bold text after the special phrase
                                                        return (
                                                            <React.Fragment key={innerIndex}>
                                                                <span>{parts[0]}(Fill in the blanks)</span>
                                                                <strong className="small-strong">{parts[1].replace(/-/g, ' ')}</strong> {/* Remove hyphens */}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    return boldText ? <strong key={innerIndex} className="small-strong">{segment.content.replace(/-/g, ' ')}</strong> : <span key={innerIndex}>{segment.content}</span>; {/* Remove hyphens */}
                                                } else if (segment.type === "gap") {
                                                    const inputName = `a${inputCounter++}`; // Increment the counter for each input
                                                    return (
                                                        <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                            <input required
                                                                   name={inputName}
                                                                   className="gap-input"
                                                                   placeholder="..."
                                                                   style={{ width: `${segment.content.length * 20}px` }} // Adjust width based on content length
                                                            />
                                                        </span>
                                                    );
                                                } else if (segment.type === "word_with_gap") {
                                                    const inputName = `a${inputCounter++}`; // Increment the counter for each input
                                                    return (
                                                        <span key={innerIndex} style={{ whiteSpace: "nowrap" }}>
                                                            <span>{segment.prefix}</span>
                                                                <input required
                                                                       name={inputName}
                                                                       className="gap-input"
                                                                       placeholder="..."
                                                                       style={{ width: `${segment.gap.length * 20}px` }} // Adjust width based on content length
                                                                />
                                                            <span>{segment.suffix}</span>
                                                        </span>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </p>
                                    ));
                                })()}
                            </div>
                        </div>
                    )}

                    {/*... other rendering logic for other QTypes can go here */}

                    {/* The Button... OF DOOOOOM!!! */}
                    {!isValid && <p>You must select at least one option!</p>}
                    <div className="btn-container" style={{ marginTop: '20px', zIndex: 10000 }}>
                        <button className={"btn-primary"} style={{zIndex: 10000}} type="submit">{buttonText}</button>
                    </div>

                    <input type="hidden" name="qNum" value={data.questionNum - questionNumFix} />
                    <input type="hidden" name="assessmentModsId" value={module} />
                    <input type="hidden" name="questionCount" value={assessmentCount} />
                    <input type="hidden" name="studentId" value={window.sessionStorage.getItem("studentId")} />
                    <input type="hidden" name="trainerId" value={window.sessionStorage.getItem("trainerId")} />

                </form>
            </div>
        );
    }

    // const uploadFile = (file, newName, studentNumber, moduleCode, certCode) => {
    //
    //     // Notes
    //     // Add the function to save and begin
    //
    //     return new Promise((resolve, reject) => {
    //         const formData = new FormData();
    //         formData.append("file", file, newName || file.name);
    //         formData.append("studentNumber", studentNumber); // Add studentNumber to the form data
    //         formData.append("moduleCode", moduleCode); // Add moduleCode to the form data
    //         formData.append("certCode", certCode); // Add certCode
    //
    //         // Log the metadata for debugging
    //         console.log("Uploading file:", {
    //             fileName: newName || file.name,
    //             studentNumber: studentNumber,
    //             moduleCode: moduleCode,
    //             certCode: certCode, // Log certCode
    //             originalFileName: file.name
    //         });
    //
    //         const req = new XMLHttpRequest();
    //
    //         req.onload = () => {
    //             if (req.status >= 200 && req.status < 300) {
    //                 resolve(req.response);
    //             } else {
    //                 reject(`Failed to upload file. Status: ${req.status}`);
    //             }
    //         };
    //
    //         req.onerror = () => {
    //             reject("Failed to upload file.");
    //         };
    //
    //         req.open("POST", "https://localhost:3001/student/upload");
    //         req.send(formData);
    //     });
    // };

    const handleBegin = () => {
        setShowCoverSheet(false);
        setCurrentQuestionIndex(0);

    };

    return (
        <div style={{padding: '50px 0'}}>
            <Bluey />
            <div className="page-common" style={{padding: 25}}>
                {/*{completed ? (*/}
                {/*    <div style={{ flex: 1, display: 'flex', flexDirection: 'column'}}>*/}
                {/*        <div>*/}
                {/*            <h1>Thank you. You have now finished this assessment!</h1>*/}
                {/*            <SignaturePanel annotations={annotations} />*/}
                {/*        </div>*/}
                {/*        <div className="btn-container" style={{ margin: '0 0', padding: '10px 20px', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                {/*            <button className="btn-primary" style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }} onClick={() => navigate('/')}>Complete</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    assessmentData.length > 0 &&*/}
                {/*    <PageComponent*/}
                {/*        data={assessmentData[currentQuestionIndex]}*/}
                {/*        // onNext={handleNextQuestion}*/}
                {/*        currentQuestionIndex={currentQuestionIndex}*/}
                {/*        totalQuestions={assessmentData.length - 2}*/}
                {/*    />*/}
                {/*)}*/}
                {completed ? (
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <h1>Thank you. You have now finished this assessment!</h1>
                            <SignaturePanel annotations={annotations} />
                        </div>
                        <div className="btn-container" style={{ margin: '0 0', padding: '10px 20px', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn-primary" style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }} onClick={() => navigate('/')}>Complete</button>
                        </div>
                    </div>
                ) : showCoverSheet ? (
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <SignaturePanel annotations={annotations} />
                        <div className="btn-container" style={{ margin: '0 0', padding: '10px 20px', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn-primary" style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }} onClick={handleBegin}>Begin Assessment</button>
                        </div>
                    </div>
                ) : (
                    assessmentData.length > 0 &&
                    <PageComponent
                        data={assessmentData[currentQuestionIndex]}
                        // onNext={handleNextQuestion}
                        currentQuestionIndex={currentQuestionIndex}
                        totalQuestions={assessmentData.length - 2}
                    />
                )}
            </div>
        </div>
    );

}


// =============================== To Do =================================== //



// =============================== Bugs =================================== //