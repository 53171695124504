// import axios from 'axios';
//
// const logError = async (errorDetails) => {
//     const endpoint = 'https://localhost:3001/errors/logError';
//     const companyCode = sessionStorage.getItem('companyCode'); // Will need to be added at login
//     const userId = sessionStorage.getItem('user_id');
//     const permissionLevel = sessionStorage.getItem('permission');
//     const ipAddress = sessionStorage.getItem('ipAddress'); // Make sure this is stored during login
//
//     try {
//         await axios.post(endpoint, {
//             companyCode,
//             userId,
//             permissionLevel,
//             // ipAddress, // Do this at the endpoint
//             page: errorDetails.page,
//             dataSubmitted: errorDetails.data,
//             errorData: errorDetails.data,
//             error: errorDetails.error,
//             errorCode: errorDetails.errorCode,
//             errorLine: errorDetails.line,
//             errorDesc: errorDetails.errorDesc,
//         });
//         console.log('Error logged successfully');
//     } catch (err) {
//         console.error('Failed to log error:', err);
//     }
// };
//
// export default logError;

const logError = async (errorDetails) => {

    const endpoint = 'https://api.trainingprofessionals.com.au/errors/logError';
    let companyCode = sessionStorage.getItem('companyCode'); // Will need to be added at login
    if (companyCode === '') {
        companyCode = 1
    }
    const userId = sessionStorage.getItem('user_id');
    // console.log('UserID: ', userId);
    const permissionLevel = sessionStorage.getItem('permission');
    const ipAddress = sessionStorage.getItem('ipAddress'); // Make sure this is stored during login
    const origin = 'Student Portal'
    const requestBody = JSON.stringify({
        companyCode,
        userId,
        permissionLevel,
        origin,
        ipAddress,
        endpoint: errorDetails.endpoint,
        page: errorDetails.page,
        dataSubmitted: errorDetails.dataSubmitted,
        dataReturned: errorDetails.dataReturned,
        errorLine: errorDetails.line,
        errorCode: errorDetails.errorCode,
        errorMsg: errorDetails.errorMsg,
        errorStack: errorDetails.errorStack,
        errorDesc: errorDetails.errorDesc,
        erroneousData: errorDetails.data,

    });

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include', // Ensure credentials are handled correctly server-side
            body: requestBody
        });

        if (response.ok) {
            console.log('Error logged successfully');
            return await response.json();
        } else {
            throw new Error(`Failed to log error, server responded with status: ${response.status}`);
        }
    } catch (err) {
        console.error('Failed to log error:', err);
    }
};

export default logError;

