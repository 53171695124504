import React, {useContext, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import {ConstLink} from "../ConstLink";
// import { Alert, AlertTitle } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGoogle, faWindows } from '@fortawesome/free-brands-svg-icons';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import CustomAlert from './CustomAlert';
import '../Styling/index.css';
import logError from './Logger';

const LoginComponent = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({"longExpiry":false});
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    const { isLoggedIn, setIsLoggedIn } = useContext(ConstLink);
    const [accountLinkInfo, setAccountLinkInfo] = useState({user_id: '', email: '', fb_user_id: '' });
    const [socialMediaName, setSocialMediaName] = useState('');
    const [socialMediaBlurb, setSocialMediaBlurb] = useState('');
    const [showPasswordSetupModal, setShowPasswordSetupModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // useEffect(() => {
    //     // initClient();
    // }, []);


    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    // Initiate Google client SDK
    // const initClient = () => {
    //     window.gapi.load('auth2', function() {
    //         window.gapi.auth2.init({
    //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //             scope: 'profile email'
    //         });
    //     });
    // };  <---------------------------------- This is in the App.js file

    const handleGoogleLogin = () => {
        // const GoogleAuth = window.gapi.auth2.getAuthInstance();
        //
        // GoogleAuth.signIn().then((googleUser) => {
        //     const profile = googleUser.getBasicProfile();
        //     const id_token = googleUser.getAuthResponse().id_token;
        //
        //     console.log(profile)
        //
        //     // Similar to your Facebook logic, make a POST request with this token
        //     fetch('https://localhost:3001/oauth/google_login', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ idToken: id_token }),
        //     })
        //         .then(response => response.json().then(data => {
        //             // Your logic for handling response goes here
        //             // Similar switch case as you did for Facebook
        //         }))
        //         .catch(error => console.error('Error posting Google login data:', error));
        // }, (error) => {
        //     console.log('Google sign in error', error);
        //     // Handle sign in errors here
        // });
    };

    const handleMicrosoftLogin = () => {
        // Logic to handle Microsoft login
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleOpenModal = (mediaName, modal) => {

        // Set the social media name and any other dynamic info
        setSocialMediaName(mediaName);

        // console.log('Social Media: ', socialMediaName)
        // console.log('Account Info: ', accountLinkInfo)

        if (modal === 'verify') {
            setShowPasswordModal(true);
        } else if (modal === 'setup') {
            setShowPasswordSetupModal(true);
        }

    };

    const fetchStudentHistory = (data) => {

        // console.log(data);
        const endpoint = 'https://api.trainingprofessionals.com.au/student/student_history';
        const studentId = data.studentNumber;
        // window.localStorage.setItem('studentId', studentId);
        const requestBody = JSON.stringify({ "studentID": studentId });

        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Removed the Authorization header since the token should be automatically sent with the request via HttpOnly cookies
            },
            credentials: 'include',
            body: requestBody
        })
            .then(response => response.json().then(data => {

                switch(response.status) {
                    case 200:
                        // console.log('Student history fetched successfully');
                        console.log('Student History: ', data);
                        sessionStorage.setItem('studentHistory', JSON.stringify(data.student_history));


                        setMsgCode(4);
                        break;
                    case 400:
                        // setMsg('Unauthorized');
                        setMsgCode(1);
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorCode: response.status,
                            errorDesc: 'Browser failed to provide a token', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Student history unable to be fetched. No authorization token provided. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });
                        break;
                    case 401:
                        // console.log('Unauthorized access');
                        // setMsg('Unauthorized');
                        setMsgCode(1);
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorCode: response.status,
                            errorDesc: 'Expired or invalid token provided', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Student history unable to be fetched. Authorization token expired. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });
                        break;
                    case 404:
                        // console.log('Unauthorized access');
                        setMsg('User not found');
                        setMsgCode(1);
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorCode: response.status,
                            errorDesc: 'Failed to fetch student history. User not found', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Failed to fetch student history. User not found. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });
                        break;
                    case 429:
                        // console.log('Too many requests');
                        alert('Too many requests, please try again later');
                        setMsg('Rate limit exceeded');
                        setMsgCode(2);
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorCode: response.status,
                            errorDesc: 'Too many server requests', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Too many server requests. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });
                        break;
                    default:
                        console.log('O_o    Sumfin broke real bad Boss!');
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorDesc: 'Failed to login user, Fetch returned a response code that was not recognised as a catchable case', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Server authorisation failed. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with error logging service');
                            setMsgCode(3);
                        });
                }

            }))

            .catch(error => {

                logError({
                    page: location.pathname,
                    dataSubmitted: requestBody,
                    errorData: error.data,
                    errorCode: error.errorCode,
                    errorStack: error.stack,
                    errorMsg: error.message,
                    errorDesc: 'Failed to fetch student history.', // Detailed description of the error
                    endpoint: endpoint,
                }).then(response => {
                    console.log('Server responded with:', response);
                    setMsg(`Failed to fetch student history. Event ID: ${response.insertedId}`); // Example of using the response
                    setMsgCode(2);
                }).catch(err => {
                    console.error('Error logging failed:', err);
                    setMsg('Failed to communicate with error logging service');
                    setMsgCode(3);
                });

            });
    }

    const handleLogin = (data) => {
        // console.log('Login Data: ', data);
        window.sessionStorage.setItem("email", data.email);
        window.sessionStorage.setItem("username", data.username);
        window.sessionStorage.setItem("user_name", data.user_name);
        window.sessionStorage.setItem("user_id", data.user_id);
        window.sessionStorage.setItem("timerSet", "no");
        window.sessionStorage.setItem("userLoggedIn", "true");
        window.sessionStorage.setItem("message", data.message);
        window.sessionStorage.setItem("timerStartTime", "");
        window.sessionStorage.setItem("timerStartTime", Date.now());
        window.sessionStorage.setItem("permission", data.permission || 1);
        window.sessionStorage.setItem("companyCode", data.companyCode || 1);
        // window.sessionStorage.setItem("ipAddress", data.ipAddress);
        window.sessionStorage.setItem("studentId", data.student_id);
        // window.sessionStorage.setItem("apprenticeModsId", data.apprentice_mods_id);

        fetchStudentHistory(data.student_details);
        setIsLoggedIn(true);
    }

    const PasswordSetupModal = ({ show, onHide, onSubmitPassword, socialMediaName, socialMediaBlurb }) => {

        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [showPassword1, setShowPassword1] = useState(false);
        const [showPassword2, setShowPassword2] = useState(false);
        const [error, setError] = useState('');

        const socialMediaLogo = `${process.env.PUBLIC_URL}/Images/web.png`;
        const siteLogo = `${process.env.PUBLIC_URL}/Images/logo.png`;
        const linkingIcon = `${process.env.PUBLIC_URL}/Images/link.png`;

        const handleRetry = () => {
            handleSetupPassword()
        }

        const handleSetupPassword = (e) => {
            // e.preventDefault();

            if (password !== confirmPassword) {
                setError('Passwords do not match.');
                setMsg('Passwords do not match.');
                setMsgCode(1);
                return;
            }

            // setMsg('');
            // setMsgCode(0);

            // Call the API to add the password to the user's account.
            fetch('', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // Removed the Authorization header since the token should be automatically sent with the request via HttpOnly cookies
                },
                credentials: 'include',
                body: JSON.stringify({
                    // Adjusted to remove the username from the request payload if it's not needed for identification
                    // The server can identify the user based on the token
                    "updates": {
                        "password": password
                    }
                })
            })
                .then(response => response.json().then(data => {

                    switch(response.status) {
                        case 200:
                            setMsgCode(4);
                            navigate('/Profile')
                            setShowPasswordSetupModal(false);
                            break;
                        case 401:
                            console.log('Invalid Token.');
                            setMsg('Token authorization failed. Please login again.')
                            setMsgCode(1);
                            break;
                        case 402:
                            setMsg('Passwords cannot be blank. Please enter a password and try again.')
                            setMsgCode(1);
                            break;
                        case 403:
                            console.log('Bearer token expired. Attempting to refresh.');
                            fetch('', {
                                method: 'POST',
                                credentials: 'include', // Necessary to include cookies if the refresh token is stored in an HttpOnly cookie
                            })
                                .then(refreshResponse => {
                                    if (refreshResponse.ok) {
                                        // Assuming the server responds with the new bearer token in the response body or a cookie
                                        console.log('Token refreshed successfully.');
                                        setMsg('Authorization token has been refreshed.');
                                        setMsgCode(2);
                                        // Retry the original request with the new token
                                        handleRetry(); // Call the original function again
                                    } else {
                                        console.log('Failed to refresh token.');
                                        setMsg('Session expired. Please log in again.');
                                        setMsgCode(1);
                                        navigate('/Login')
                                    }
                                })
                                .catch(error => {
                                    console.error('Error attempting to refresh token:', error);
                                    setMsg('Session expired. Please log in again.');
                                    setMsgCode(1);
                                    navigate('/Login')

                                    const stackLines = error.stack ? error.stack.split('\n') : [];
                                    let errorOnLine = stackLines.length > 1 ? stackLines[1].trim() : ''; // Taking the first relevant line of the stack

                                    logError({
                                        page: location.pathname,
                                        data: error.data,
                                        errorCode: error.errorCode,
                                        errorLine: errorOnLine, // Contains the line where the error was thrown if available
                                        error: error.message,
                                        errorDesc: 'Refresh Token not returned.', // Detailed description of the error
                                    });
                                });
                            break;
                        case 404:
                            setMsg('Your account could not be found. Please try again or contact support.')
                            setMsgCode(1);
                            break;
                        case 405:
                            setMsg('Your password does not meet the required security level. Please ensure that it' +
                                ' contains at least 8 characters, including at least one uppercase letter, one lowercase ' +
                                'letter, one number, and one special character.')
                            setMsgCode(2);
                            break;
                        case 500:
                            console.log('Internal Error... Probably a server or MySQL issue');
                            setMsg('Something broke real bad. Try again later or contact support.')
                            setMsgCode(1);
                            break;
                        default:
                            console.log('O_o    Sumfin broke real bad Boss!');

                            logError({
                                page: location.pathname,
                                data: error.data,
                                error: error.message,
                                errorCode: error.message,
                                errorDesc: 'errorDesc', //Detailed description of the error
                            });
                    }

                }))

                // .catch(err => err);

                .catch(err => {
                    logError({

                        page: location.pathname,     // Replace with actual page/component name
                        data: 'Data involved',       // Describe data involved if applicable
                        errorCode: err.message,      // Error message
                        errorDesc: 'Detailed error description', // Detailed error context

                    }).then(() => {
                        console.log('Error logged');
                    }).catch(logError => {
                        console.error('Failed to log error:', logError);
                    });

                    return err; // You can still return the error if needed
                });


        };



        // Assuming handleChange handles both password and confirmPassword inputs
        const handleChange = (e) => {
            if (e.target.name === "password") {
                setPassword(e.target.value);
            } else if (e.target.name === "password2") {
                setConfirmPassword(e.target.value);
            }
        };

        const togglePasswordVisibility1 = () => {
            setShowPassword1(!showPassword1);
        };

        const togglePasswordVisibility2 = () => {
            setShowPassword2(!showPassword2);
        };

        return (

            <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>

                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>Set Up Password</Modal.Title>*/}
                {/*</Modal.Header>*/}

                <Modal.Body style={{}}>
                    <Form onSubmit={handleSetupPassword}>
                        <MDBContainer style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px 0', }}>
                                {/* Site logo */}
                                <img src={siteLogo} alt="Site Logo" style={{ width: 'auto', height: 200 }} />
                                {/* Linking icon */}
                                <img src={linkingIcon} alt="Linking" style={{ width: 'auto', height: 100, marginTop: 50, marginLeft: -20 }} />
                                {/* Social media logo */}
                                <img src={socialMediaLogo} alt="Social Media Logo" style={{ maxWidth: '200px', padding: 10 }} />
                            </div>

                            <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ }}>
                                <h3 style={{textAlign: 'center' }}>PLEASE SET UP A PASSWORD</h3>
                                {/* Blurb explaining why they need to set up a password */}
                                <p style={{textAlign: 'center', marginBottom: 25, color: '#000'}}>
                                    Although you will never need to enter your password to log in with {socialMediaName},
                                    you will need an account password to link any other social media accounts to your
                                    Training Professionals account. If you skip this step you will need to manually provide your social
                                    media links for each account you want to link.
                                </p>

                                <CustomAlert msg={msg} msgCode={msgCode} />

                                <div style={{ position: 'relative'}}>
                                    <input
                                        type={showPassword1 ? 'text' : 'password'}
                                        name="password"
                                        className="inputBox"
                                        placeholder={"ENTER A PASSWORD"}
                                        onChange={handleChange}
                                        style={{ paddingRight: '40px' }} // Make room for the icon
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword1 ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility1}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '15px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d' // Example color, adjust as needed
                                        }}
                                    />
                                </div>

                                <div style={{ position: 'relative', paddingTop: 10 }}>
                                    <input
                                        type={showPassword2 ? 'text' : 'password'}
                                        className="inputBox"
                                        placeholder={"CONFIRM YOUR PASSWORD"}
                                        style={{ paddingRight: '40px', fontColor: '#886b26' }} // Make room for the icon
                                        name="password2"
                                        onChange={handleChange}

                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword2 ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility2}
                                        style={{
                                            position: 'absolute',
                                            top: '56%',
                                            right: '15px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d' // Example color, adjust as needed
                                        }}
                                    />
                                </div>

                                <input type="hidden" name="longExpiry" value="false"/>

                                <MDBRow>
                                    <MDBCol center>
                                        {/* Change the type to "button" and add an onClick handler */}
                                        <button
                                            style={{ marginTop: 15, marginBottom: 30, color: '#fff'}}
                                            className="btn-primary"
                                            type="button" // Changed from "submit" to "button"
                                            onClick={handleSetupPassword} // Add the click event handler here
                                        >
                                            UPDATE PASSWORD
                                        </button>
                                    </MDBCol>
                                </MDBRow>

                            </div>
                        </MDBContainer>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };


    const PasswordVerificationModal = ({ show, onHide, onVerify, socialMediaName, socialMediaBlurb }) => {

        const [password, setPassword] = useState('');
        const [showPassword, setShowPassword] = useState(false);
        const socialMediaLogo = `${process.env.PUBLIC_URL}/Images/${socialMediaName}.png`;
        const siteLogo = `${process.env.PUBLIC_URL}/Images/logo.png`;
        const linkingIcon = `${process.env.PUBLIC_URL}/Images/link.png`;

        const handleRetry = (e) => {
            handleVerifyPassword(e)
        }

        const handleVerifyPassword = (e) => {
            // // e.preventDefault();
            // setMsg('');
            // setMsgCode(0);

            // Call the API and checks the users password to the user's account before updating.
            fetch('', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // Removed the Authorization header since the token should be automatically sent with the request via HttpOnly cookies
                },
                credentials: 'include',
                body: JSON.stringify({
                    // Adjusted to remove the username from the request payload if it's not needed for identification
                    // The server can identify the user based on the token
                    "password": password,
                    "updates": {
                        "fb_user_id": accountLinkInfo.fb_user_id,
                    }
                })
            })
                .then(response => response.json().then(data => {

                    switch(response.status) {
                        case 200:
                            setMsgCode(4);
                            navigate('/Profile')
                            setShowPasswordModal(false);
                            break;

                        case 400:
                            setMsg('Incorrect password. Please try again.')
                            setMsgCode(1);
                            break;
                        case 401:
                            console.log('Invalid Token.');
                            setMsg('Token authorization failed. Please login again.')
                            setMsgCode(1);
                            break;
                        case 402:
                            setMsg('Password cannot be blank. Please enter password and try again')
                            setMsgCode(1);
                            break;
                        case 403:
                            console.log('Bearer token expired. Attempting to refresh.');
                            fetch('', {
                                method: 'POST',
                                credentials: 'include', // Necessary to include cookies if the refresh token is stored in an HttpOnly cookie
                            })
                                .then(refreshResponse => {
                                    if (refreshResponse.ok) {
                                        // Assuming the server responds with the new bearer token in the response body or a cookie
                                        console.log('Token refreshed successfully.');
                                        setMsg('Authorization token has been refreshed.');
                                        setMsgCode(1);
                                        // Retry the original request with the new token
                                        handleRetry(e); // Call the original function again
                                    } else {
                                        console.log('Failed to refresh token.');
                                        setMsg('Session expired. Please log in again.');
                                        setMsgCode(1);
                                        navigate('/Login')
                                    }
                                })
                                .catch(refreshError => {
                                    console.error('Error attempting to refresh token:', refreshError);
                                    setMsg('Session expired. Please log in again.');
                                    setMsgCode(1);
                                    // Redirect to login or show a login modal
                                    navigate('/Login')
                                });
                            break;
                        case 404:
                            setMsg('Your account could not be found. Please try again or contact support.')
                            setMsgCode(1);
                            break;
                        case 429:
                            console.log('Too many login attempts');
                            alert('Too many login attempts - account has been locked!')
                            setMsg('Account locked due to too many failed attempts, please try again later.')
                            setMsgCode(2);
                            break;
                        case 500:
                            console.log('Internal Error... Probably a server or MySQL issue');
                            setMsg('Something broke real bad. Try again later or contact support.')
                            setMsgCode(1);
                            break;
                        default:
                            console.log('O_o    Sumfin broke real bad Boss!');
                    }

                }))

                .catch(err => err);

        }

        // Assuming handleChange handles both password and confirmPassword inputs
        const handleChange = (e) => {
            if (e.target.name === "password") {
                setPassword(e.target.value);
            }
        };

        const togglePasswordVisibility = () => {
            setShowPassword(!showPassword);
        };


        return (

            <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>

                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>Set Up Password</Modal.Title>*/}
                {/*</Modal.Header>*/}

                <Modal.Body style={{}}>
                    <Form onSubmit={handleVerifyPassword}>
                        <MDBContainer style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px 0', }}>
                                {/* Site logo */}
                                <img src={siteLogo} alt="Site Logo" style={{ width: 'auto', height: 200 }} />
                                {/* Linking icon */}
                                <img src={linkingIcon} alt="Linking" style={{ width: 'auto', height: 100, marginTop: 50, marginLeft: -20 }} />
                                {/* Social media logo */}
                                <img src={socialMediaLogo} alt="Social Media Logo" style={{ maxWidth: '200px', padding: 10 }} />
                            </div>

                            <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ }}>
                                <h3 style={{textAlign: 'center'}}>PLEASE ENTER YOUR TP ACCOUNT PASSWORD</h3>
                                {/* Blurb explaining why they need to set up a password */}
                                <p style={{textAlign: 'center', marginBottom: 25, color: '#000'}}>
                                    It looks like there is already an existing TP account with the same name and email.
                                    To link your {socialMediaName} account to TP, please enter the password associated
                                    with your TP.com profile. This is a one-time process and you will not need to enter
                                    this password again if you login with {socialMediaName} again in the future.
                                </p>

                                <CustomAlert msg={msg} msgCode={msgCode} />

                                <div style={{ position: 'relative'}}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        className="inputBox"
                                        placeholder={"ENTER A PASSWORD"}
                                        onChange={handleChange}
                                        style={{ paddingRight: '40px' }} // Make room for the icon
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '15px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            color: '#6c757d'
                                        }}
                                    />
                                </div>

                                <MDBRow>
                                    <MDBCol center>
                                        {/* Change the type to "button" and add an onClick handler */}
                                        <button
                                            style={{ marginTop: 15, marginBottom: 30 }}
                                            className="btn-primary"
                                            type="button" // Changed from "submit" to "button"
                                            onClick={handleVerifyPassword} // Add the click event handler here
                                        >
                                            UPDATE PASSWORD
                                        </button>
                                    </MDBCol>
                                </MDBRow>

                            </div>
                        </MDBContainer>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };

    // Function that makes a POST request to the TP API with the Access token for a more secure data retrieval from Facebook
    const handleFacebookLogin = () => {

        // Initialization remains the same...
        window.FB.init({
            appId      : process.env.REACT_APP_FB_APP_ID,
            cookie     : true,  // Enable cookies to allow the server to access the session
            xfbml      : true,  // Parse social plugins on this webpage
            version    : 'v10.0' // Use version 10.0 of the Facebook API
        });

        window.FB.login((loginResponse) => {
            if (loginResponse.authResponse) {

                // Send the accessToken in the body of a POST request
                const accessToken = loginResponse.authResponse.accessToken;

                fetch('', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ accessToken }),
                })

                    // .then(response => response.json())
                    // .then(data => console.log(data))
                    // .catch(error => console.error('Error posting data:', error));

                    .then(response => response.json().then(data => {

                        switch(response.status) {
                            case 200:
                                setMsgCode(4);
                                navigate('/Profile')
                                handleLogin(data);
                                break;
                            case 201:
                                setMsgCode(4);
                                // setSocialMediaName(mediaName);
                                // setShowPasswordSetupModal(true); // Show the modal to ask for the user's password
                                handleLogin(data);
                                handleOpenModal('Facebook', 'setup')
                                break;
                            case 400:
                                setMsg('Someone else is already using the email address associated with the facebook account you are using. Please use a different account or try registering manually.')
                                setMsgCode(1);
                                break;
                            case 401:
                                console.log('Facebook credentials are either invalid or user has not authorized the app.');
                                setMsg('Facebook authorization failed. Please try again or use a different method to sign in.')
                                setMsgCode(1);
                                break;
                            case 409:
                                console.log('User already exists, but not with facebook.');
                                setAccountLinkInfo({ user_id: data.user_id, email: data.email, fb_user_id: data.fb_user_id });
                                // setShowPasswordModal(true); // Show the modal to ask for the user's password
                                handleLogin(data);
                                handleOpenModal('Facebook', 'verify')
                                break;
                            case 429:
                                console.log('Too many requests');
                                alert('WOOOAH... Too many requests - Slow down Turbot!')
                                setMsg('A network error has occurred. Please try again later!')
                                setMsgCode(2);
                                break;
                            case 500:
                                console.log('Internal Error... Probably a server or MySQL issue');
                                setMsg('Something broke real bad. Try again later or contact support.')
                                setMsgCode(1);
                                break;
                            default:
                                console.log('O_o    Sumfin broke real bad Boss!');
                        }

                    }))

                    .catch(err => err);

            } else {
                console.log('Failed to log in with facebook.');
                setMsg('Failed to log in with facebook.')
                setMsgCode(1);
            }
            // }, {scope: 'email, user_link, public_profile, user_gender, user_birthday, user_location'});
        }, {scope: 'email, user_link, public_profile, user_birthday'});
    };

    function togglePasswordVisibility() {
        const passwordInput = document.querySelector('[name="password"]');
        if (!showPassword) {
            passwordInput.setAttribute('type', 'text');
        } else {
            passwordInput.setAttribute('type', 'password');
        }
        setShowPassword(!showPassword);
    }

    const handleTPLogin = (event) => {

        event.preventDefault();

        // console.log(inputs);
        const requestBody = JSON.stringify(inputs);
        const endpoint = 'https://api.trainingprofessionals.com.au/user/login';

        // fetch('https://api.trainingprofessionals.com.au/user/login', {
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: requestBody
        })

            .then(response => response.json().then(data => {

                switch(response.status) {
                    case 200:
                        handleLogin(data);
                        setMsgCode(4);
                        // window.location.href = '/';
                        navigate('/Study')
                        break;
                    case 201:
                        handleLogin(data);
                        setMsgCode(4);
                        // window.location.href = '/';
                        navigate('/')
                        break;
                    case 400:
                        setMsg('Please enter a valid username and password.')
                        setMsgCode(1);
                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody, // capture the fetch data here...
                            errorCode: response.status,
                            errorDesc: 'Failed to login user. User provided invalid username or password.', // Detailed description of the error
                            endpoint: endpoint,
                        });
                        break;
                    case 429:
                        console.log('Too many login attempts - account has been locked!');
                        setMsg('Account locked for 10 minutes due to too many failed attempts. Please try again later.')
                        setMsgCode(2);

                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorCode: response.status,
                            errorDesc: 'Failed to login user, Too many login attempts - account has been locked!', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Server authorisation failed. Account temporarily locked. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });

                        alert('Too many login attempts - account has been locked!')
                        break;

                    case 500:
                        console.log('An error occurred at the endpoint');
                        setMsg(data.message)
                        setMsgCode(2);
                        break;

                    default:
                        console.log('O_o    Sumfin broke real bad Boss!');

                        logError({
                            page: location.pathname,
                            dataSubmitted: requestBody,
                            errorDesc: 'Failed to login user, Fetch returned a response code that was not recognised as a catchable case', // Detailed description of the error
                            endpoint: endpoint,
                        }).then(response => {
                            console.log('Server responded with:', response);
                            setMsg(`Server authorisation failed. Event ID: ${response.insertedId}`); // Example of using the response
                            setMsgCode(2);
                        }).catch(err => {
                            console.error('Error logging failed:', err);
                            setMsg('Failed to communicate with server');
                            setMsgCode(3);
                        });
                }

            }))

            .catch(error => {
                logError({
                    page: location.pathname,
                    dataSubmitted: requestBody,
                    errorData: error.data,
                    errorCode: error.errorCode,
                    errorStack: error.stack,
                    errorMsg: error.message,
                    errorDesc: 'Failed to login user', // Detailed description of the error
                    endpoint: endpoint,
                }).then(response => {
                    console.log('Server responded with:', response);
                    setMsg(`Server authorisation failed. Event ID: ${response.insertedId}`); // Example of using the response
                    setMsgCode(2);
                }).catch(err => {
                    console.error('Error logging failed:', err);
                    setMsg('Failed to communicate with server');
                    setMsgCode(3);
                });
            });

    }

    return (

        <div>
            <div style={{
                backgroundImage: `url("")`,
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>

                <form onSubmit={handleTPLogin}>

                    <MDBContainer style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: 'transparent'
                    }}>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ height: "1000px" }}>

                            <div className="bg-transparent mb-12" style={{ height: "10vh" }}/>

                            <h1 style={{textAlign: 'center', marginBottom: 25 , marginTop: 200 }}>PLEASE ENTER YOU USERNAME AND PASSWORD</h1>

                            <CustomAlert msg={msg} msgCode={msgCode} />

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>

                                    <input
                                        type="text"
                                        name="identifier"
                                        className="inputBox"
                                        placeholder={"USERNAME"}
                                        onChange={handleChange}
                                        title='Your UserName is the email Address or Phone number you signed up with.'
                                        required
                                    />


                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>

                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type="password"  // Always 'password' to support autofill
                                            name="password"
                                            className="inputBox"
                                            placeholder="PASSWORD"
                                            onChange={handleChange}
                                            title='Ensure your password has at least 8 characters including 1 uppercase, 1 lowercase, 1 number, and 1 special character'
                                            style={{ paddingRight: '40px' }} // Make room for the icon
                                        />
                                        <FontAwesomeIcon
                                            icon={showPassword ? faEyeSlash : faEye}
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '15px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                color: '#6c757d'
                                            }}
                                        />
                                    </div>

                                </MDBCol>
                            </MDBRow>


                            <input type="hidden" name="longExpiry" value="false"/>

                            <MDBRow>
                                <MDBCol center>
                                    <button style={{margin: '5px 0'}} className="btn-primary" type="submit">SIGN IN</button>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol center>
                                    <button style={{margin: '5px 0' }} className="btn-primary" type="button" onClick={() => navigate('/')}>CANCEL </button>
                                </MDBCol>
                            </MDBRow>

                            {/*========== Social media logins (DO NOT DELETE) ===========*/}

                            {/*<div style={{ textAlign: 'center', marginTop: '20px' }}>*/}
                            {/*    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>*/}
                            {/*        <hr style={{ width: '30%', borderTop: '3px solid', margin: '0', paddingBottom: 10 }}/>*/}
                            {/*        <span style={{ padding: '0 10px' }}>*/}
                            {/*            <h3>OR SIGN IN WITH</h3>*/}
                            {/*        </span>*/}
                            {/*        <hr style={{ width: '30%', borderTop: '3px solid', margin: 0, paddingBottom: 10 }}/>*/}
                            {/*    </div>                                */}

                            {/*    <div style={{*/}
                            {/*        display: 'flex',*/}
                            {/*        justifyContent: 'center',*/}
                            {/*        marginTop: '20px'*/}
                            {/*    }}>*/}
                            {/*        <button type="button" className="fb btn " onClick={handleFacebookLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
                            {/*            <FontAwesomeIcon icon={faFacebook} size="2x"/>*/}
                            {/*        </button>*/}
                            {/*    */}
                            {/*        <button type="button" className="google btn" onClick={handleGoogleLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
                            {/*            <FontAwesomeIcon icon={faGoogle} size="2x"/>*/}
                            {/*        </button>*/}
                            {/*    */}
                            {/*        <button type="button" className="microsoft btn" onClick={handleMicrosoftLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
                            {/*            <FontAwesomeIcon icon={faWindows} size="2x"/>*/}
                            {/*        </button>*/}
                            {/*   </div>                         */}

                            {/*</div>*/}

                            {/*========== Social media logins (DO NOT DELETE) ===========*/}

                            {/* Password Verification Modal */}
                            <PasswordVerificationModal
                                show={showPasswordModal}
                                onHide={() => setShowPasswordModal(false)}
                                // onVerify={(password) => verifyPasswordAndLinkAccount(password)}
                                socialMediaBlurb={socialMediaBlurb}
                                socialMediaName={socialMediaName}
                            />

                            {/* Password Setup Modal */}
                            {showPasswordSetupModal && (
                                <PasswordSetupModal
                                    show={showPasswordSetupModal}
                                    onHide={() => setShowPasswordSetupModal(false)}
                                    // onSubmitPassword={handleSetupPassword}
                                    socialMediaBlurb={socialMediaBlurb}
                                    socialMediaName={socialMediaName}
                                />
                            )}

                        </div>
                    </MDBContainer>

                </form>
            </div>
        </div>
    );
};

export default LoginComponent

