import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Styling/main.css'; // Assuming the main.css is inside a styles folder
import Legend from './Legend';
const CertCarousel = ({ items }) => {

    const [index, setIndex] = useState(0);
    const refWrapper = useRef(null);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const navigate = useNavigate();
    const location = useLocation(); //error handler

    const nextCard = () => {
        setIndex((current) => (current + 1 + items.length) % items.length);
    };

    const prevCard = () => {
        setIndex((current) => (current - 1 + items.length) % items.length);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 50) {
            nextCard(); // Swiped left
        }
        if (touchStartX.current - touchEndX.current < -50) {
            prevCard(); // Swiped right
        }
    };

    useEffect(() => {
        if (refWrapper.current) {
            const shift = index * -100 / items.length;
            refWrapper.current.style.transform = `translateX(${shift}%)`;
        }
    }, [index]);

    // Function to determine the card class based on the certificate code
    const getCardClass = (item) => {
        // Treat blank, 'SOA', or 'SOC' codes as 'statement-card'
        const code = item.certificate?.code?.trim().toLowerCase();
        return (!code || code === 'soa' || code === 'soc') ? 'statement-card' : 'certificate-card';
    };

    // Wrapper function to decide which format function to use
    const getOutcomeOrStatus = (proposedOutcome, statusID) => {
        if (proposedOutcome === null || proposedOutcome === undefined || proposedOutcome === '') {
            return formatProposedStatusShort(statusID);
        }
        return formatProposedOutcome(proposedOutcome);
    };

    // Function to format proposedOutcome
    const formatProposedOutcome = (outcome) => {
        switch (outcome) {
            case 20:
                return `C`;
            case 30:
                return 'NYC';
            case 40:
                return 'W';
            case 51:
                return 'RPL';
            case 52:
                return 'NRP';
            case 60:
                return `CT`;
            case 61:
                return 'SS';
            case 70:
                return 'CE';
            case 81:
                return 'NAC';
            case 82:
                return 'NAN';
            case 90:
                return 'NYA';
            case null:
                return `???`;
            default:
                return `N/A`;
        }
    };

    const formatProposedStatusShort = (outcome) => {
        switch (outcome) {
            case 1:
                return `NYE`;
            case 2:
            case 3:
            case 4:
                return `E`;
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `C*`;
            case 10:
                return `W`;
            case 11:
                return `?`;
            case 13:
                return `C*`;
            case 14:
                return `C`;
            default:
                return 'N/A';
        }
    };

    const formatProposedStatus = (outcome) => {
        switch (outcome) {
            case 1:
                return `NOT YET ENROLLED`;
            case 2:
            case 3:
            case 4:
                return `ENROLLED`;
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `COMPETENT`;
            case 10:
                return `WITHDRAWN`;
            case 11:
                return `UNKNOWN`;
            case 13:
                return `COMPETENT`;
            case 14:
                return `COMPLETED`;
            default:
                return 'N/A';
        }
    };

    // const Legend = () => {
    //     const legendStyles = {
    //         position: 'fixed',
    //         bottom: '25px',
    //         left: '25px',
    //         padding: '20px',
    //         border: '1px solid #ccc',
    //         borderRadius: '10px',
    //         backgroundColor: '#efcbd7',
    //         zIndex: 1000,
    //         width: 'auto',
    //         maxWidth: 'calc(100% - 40px)',
    //         fontSize: '12px',
    //         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    //         display: window.innerWidth >= 1000 ? 'block' : 'none'
    //     };
    //
    //     return (
    //         <div className="legend-container" style={legendStyles}>
    //             <h3 style={{ marginBottom: '10px' }}>Legend</h3>
    //             <div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
    //                 <div style={{ marginRight: '20px' }}>
    //                     <ul style={{ listStyleType: 'none', padding: 0, fontSize: '12px', textAlign: 'center' }}>
    //                         <li><strong>NYE</strong> - Not Yet Enrolled</li>
    //                         <li><strong>E</strong> - Enrolled</li>
    //                         <li><strong>C*</strong> - Completed</li>
    //                         <li><strong>C</strong> - Competent</li>
    //                         <li><strong>NYC</strong> - Not Yet Competent</li>
    //                         <li><strong>W</strong> - Withdrawn</li>
    //                         <li><strong>RPL</strong> - Recognition of Prior Learning</li>
    //                         <li><strong>NRP</strong> - Not Recognized Prior Learning</li>
    //                         <li><strong>CT</strong> - Credit Transferred</li>
    //                         <li><strong>SS</strong> - Superseded</li>
    //                         <li><strong>CE</strong> - Continuing Education</li>
    //                         <li><strong>NAC</strong> - Not Achieved Competency</li>
    //                         <li><strong>NAN</strong> - Not Achieved, Not Competent</li>
    //                         <li><strong>NYA</strong> - Not Yet Assessed</li>
    //                         <li><strong>?</strong> - Unknown</li>
    //                         <li><strong>N/A</strong> - Not Available</li>
    //                     </ul>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    // Function to format certificate name for statement of attainment
    const formatCertificateName = (certificate) => {
        const code = certificate?.code?.trim().toLowerCase();
        if (code === 'soa') {
            return 'Statement of Attainment';
        } else if (code === 'soc') {
            return 'Statement of Completion';
        } else {
            return certificate?.name || 'N/A'; // Default to the actual name or 'N/A'
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = date.toLocaleDateString('en-GB', options); // Example: "7 May 1984"

        // Extract day number to append the ordinal suffix
        const day = date.getDate();
        const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || ["th", "st", "nd", "rd"][day % 100] || "th";

        // Replace the day number with the number+suffix
        formattedDate = formattedDate.replace(day, `${day}${suffix}`);

        return `${formattedDate}`;
    }

    return (

        <div>

            <Legend/>

            <div className="infinite-carousel-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}>

                <button onClick={prevCard} className="infinite-carousel-arrow left">
                    <i className="fas fa-arrow-left"></i>
                </button>

                <div className="infinite-carousel-cards-wrapper" ref={refWrapper} style={{width: `${100 * items.length}%`}}>
                    {items.map((item, idx) => (

                        <div key={idx} className={`infinite-carousel-card ${getCardClass(item)}`}
                             style={{width: `${100 / items.length}%`}}>
                            <div className="card-content">
                                <div className="background-image"></div>

                                {
                                    <h4 style={{ padding: 50 }}>
                                        {(item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc') ?
                                            formatCertificateName(item.certificate) :
                                            (item.certificate?.name || 'N/A')
                                        }
                                    </h4>
                                }
                                {
                                    (item.certificate?.code && !['soa', 'soc'].includes(item.certificate.code.trim().toLowerCase())) && (
                                        <h4 style={{ paddingBottom: 25, color: '#C5B358', zIndex: 25 }}>{item.certificate.code}</h4>
                                    )
                                }

                                <div style={{}} className="modules-section">
                                    {item.certModules?.map((module, moduleIdx) => (
                                        <div key={moduleIdx} className="module-item" style={{
                                            display: 'grid',
                                            gridTemplateColumns: '2fr 9fr 1fr',
                                            alignItems: 'flex-start',
                                            marginBottom: '10px'
                                        }}>
                                            <div className="module-code" style={{textAlign: 'left'}}>
                                                <h6 style={{"paddingRight": 10}}>{module.modDetails.code || 'N/A'}</h6>
                                            </div>
                                            <div className="module-name" style={{textAlign: 'left'}}>
                                                <h6 style={{margin: 0}}>{module.modDetails.name || 'N/A'}</h6>
                                            </div>
                                            <div className="module-code-right" style={{textAlign: 'center'}}>
                                                <h6 style={{margin: 0}}>{getOutcomeOrStatus(module.proposedOutcome, module.statusID)}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <h5 style={{paddingTop: 15, paddingBottom: 15}}>
                                    {formatProposedStatus(item.certCustom.certificateStatusID)}
                                </h5>

                                {['COMPLETED', 'COMPETENT'].includes(formatProposedStatus(item.certCustom.certificateStatusID)) && item.statementDetails && (
                                    <div>
                                        <h6 style={{ fontWeight: 'normal' }}>Awarded to</h6>
                                        <h5 style={{ color: '#C5B358', zIndex: 35 }}>{item.statementDetails.name}</h5>
                                        <h6 style={{ fontWeight: 'normal' }}>on the</h6>
                                        <h5 style={{ }}>{formatDate(item.statementDetails.date)}</h5>
                                    </div>
                                )}

                                <h6>** Please note that this is not a certificate **</h6>

                                {/*{['Completed', 'Competent'].includes(formatProposedStatus(item.certCustom.certificateStatusID)) && item.statementDetails && (*/}

                                    <div className="button-group"

                                         style={{display: 'flex', justifyContent: 'space-between', marginTop: 'auto'}}>

                                        {!['COMPETENT'].includes(formatProposedStatus(item.certCustom.certificateStatusID)) && (
                                            <button className={'btn-primary'}
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const certModulesStr = JSON.stringify(item.certModules);
                                                        window.sessionStorage.setItem("currentClassroom", certModulesStr);
                                                        window.sessionStorage.setItem("currentCertificate", formatCertificateName(item.certificate) || item.certificate?.name || 'Module List');
                                                        navigate('/Study/Classroom')
                                                    }}>
                                                Classroom
                                            </button>
                                        )}

                                        {['COMPLETED', 'COMPETENT'].includes(formatProposedStatus(item.certCustom.certificateStatusID)) && item.statementDetails && (
                                            <>
                                                <button
                                                    className="btn-primary"
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => window.open(`https://tp.edu.au/portal/tpro-files/backend/${item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc' ? 'pdf-statement' : 'pdf-certificate'}.php?id=${item.certCustom.statementID || item.statementDetails.id}&mode=I`, '_blank')}
                                                >
                                                    View Certificate
                                                </button>
                                                <button
                                                    className="btn-primary"
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => window.location.href = `https://tp.edu.au/portal/tpro-files/backend/${item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc' ? 'pdf-statement' : 'pdf-certificate'}.php?id=${item.certCustom.statementID || item.statementDetails.id}`}
                                                >
                                                    <i className="fas fa-download"></i>
                                                </button>
                                            </>
                                        )}

                                    </div>

                                {/*)}*/}

                            </div>
                        </div>
                    ))}
                </div>

                <button onClick={nextCard} className="infinite-carousel-arrow right">
                    <i className="fas fa-arrow-right"></i>
                </button>

            </div>
        </div>
    );
};


export default CertCarousel;
