import React, { useContext, useState, useRef, useEffect, Fragment } from 'react';
import { useNavigate, Link } from "react-router-dom";
import {ConstLink } from '../ConstLink';
import logo from '../Images/logo4.png';

const Header = () => {

    const navigate = useNavigate();
    const { closeHeaderMenu, toggleMenu, isActive, isLoggedIn, setIsLoggedIn } = useContext(ConstLink);

    const menuRef = useRef(null);
    // const [isActive, setIsActive] = useState(false);
    const [username, setUsername] = useState('GUEST');

    // const [activeAccordion, setActiveAccordion] = useState(null);
    // const [activeSubAccordion, setActiveSubAccordion] = useState(null);
    // const [defaultColor, setDefaultColor] = useState('#595959');
    // const [buttonColors, setButtonColors] = useState([defaultColor, defaultColor, defaultColor, defaultColor]);


    useEffect(() => {

        // Run the colorCorrection function only when the viewport size is greater than 992 pixels
        if (window.innerWidth > 992) {

        }

        // Add an event listener to handle changes in viewport size
        const handleResize = () => {
            if (window.innerWidth > 992) {

            }
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = async () => {

        const endpoint = 'https://api.trainingprofessionals.com.au/user/logout';

        try {
            // Send request to logout endpoint
            const response = await fetch(endpoint, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                // Handle logout success
                console.log('Logout successful');
            } else {
                // Handle logout error
                const data = await response.json();
                console.error(`Logout failed: ${data.message}`);
            }

            // Clear local storage
            window.localStorage.clear();
            // Clear session storage
            window.sessionStorage.clear();
            // Optionally, you can set isLoggedIn to false if you are using state management
            setIsLoggedIn(false);
            // Redirect to login page
            navigateTo('/Login');

        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleHeaderMenu = () => {
        toggleMenu();
    };

    const navigateTo = (address) => {
        if (window.innerWidth < 900) {
            closeHeaderMenu(); // Close the navigation menu by calling the toggleMenu function
        }
        navigate(address);
    };

    const handleMenuItemClick = () => {
        const checkbox1 = document.getElementById('checkbox1');
        const checkbox2 = document.getElementById('checkbox2');
        const checkbox3 = document.getElementById('checkbox3');

        if (checkbox1) {
            checkbox1.checked = false;
        }

        if (checkbox2) {
            checkbox2.checked = false;
        }

        if (checkbox3) {
            checkbox3.checked = false;
        }
    };

    // This tracks the pixel under the mouse and closes the sub menu
    // const handleMouseMove = (event, elementRef) => {
    //
    //     const { clientX } = event;
    //     const { clientY } = event;
    //
    //     const activeElement = elementRef.current;
    //     const { left, right, top, bottom } = activeElement.getBoundingClientRect();
    //
    //     // console.log('Top: ' + top);
    //     // console.log('Y:' + clientY);
    //     // console.log('Top: ' + top + ' ,Bottom: ' + bottom);
    //     // console.log('X:' + clientX + ' ,Y: ' + clientY);
    //
    //     const offsetLeftPixel = left - 300;
    //     const offsetRightPixel = right - 300;
    //     const bottomPixel = top - 60
    //
    //     if (clientX <= offsetLeftPixel || offsetRightPixel <= clientX) {
    //         // Trigger the onMouseLeave function
    //
    //     }
    //
    // };

    return (
        <div className="header-overflow">
            <nav>

                <div className="header-container">

                    <section className="menu menu--circle">
                        <input type="checkbox" id="menu__active"/>
                        <label htmlFor="menu__active" className="menu__active">
                            <div className="menu__toggle">
                                <div className="icon">
                                    <img src={logo} style={{width: 80, zIndex: 9999}} alt="roo"/>
                                </div>
                            </div>
                            <input id="checkbox1" type="radio" name="arrow--up" id="degree--up-0" hidden={true}/>
                            <input id="checkbox2" type="radio" name="arrow--up" id="degree--up-1" hidden={true}/>
                            <input id="checkbox3" type="radio" name="arrow--up" id="degree--up-2" hidden={true}/>
                            <div
                                className="menu__listings"
                                ref={menuRef}
                            >
                                <ul className="circle">
                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => navigateTo('/Portal/Administration/Setup')}className="button"><i className="fa fa-cog"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => {navigateTo('/'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-home"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-user"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-commenting"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-folder-open"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-envelope"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-calendar"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-cloud"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-id-card"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => navigateTo('/Portal/History')} className="button"><i
                                                    className="fa fa-history"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="menu__arrow menu__arrow--top">
                                <ul>
                                    <li>
                                        <label htmlFor="degree--up-0">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-1">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-2">
                                            <div className="arrow"></div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </section>

                    <div className="navbar-container" >

                        {isLoggedIn && (

                            <nav className="navbar navbar-expand-lg">

                                <button className="navbar-toggler" type="button" style={{outline: 'none', border: 'none'}}
                                        onClick={toggleHeaderMenu}
                                >
                                    <span style={{color: 'white'}}>MENU</span>
                                </button>

                                <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`}>

                                    <ul className="navbar-nav mb-2 mb-lg-0" style={{}}>

                                        <li>
                                            <button className="customSpacer"></button>
                                        </li>

                                        {/*<li>*/}
                                        {/*    <button className="customToggle"*/}
                                        {/*            onClick={() => navigateTo('/Study/Details')}>*/}
                                        {/*        DETAILS*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        <li>
                                            <button className="customToggle" onClick={() => navigateTo('/Study')}>
                                                STUDY
                                            </button>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <button className="customToggle" onClick={() => navigateTo('/Transcripts')}>*/}
                                        {/*        TRANSCRIPTS*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <button className="customToggle" onClick={() => navigateTo('/Study/Diary')}>*/}
                                        {/*        DIARY*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <button className="customToggle" onClick={() => navigateTo('/Employment')}>*/}
                                        {/*        EMPLOYMENT*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}

                                        {/*<li>*/}
                                        {/*    <button className="customToggle"*/}
                                        {/*            onClick={() => navigateTo('/study/assessment')}>*/}
                                        {/*        ASSESSMENT*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <button className="customToggle"*/}
                                        {/*            onClick={() => navigateTo('/study/enrolment')}>*/}
                                        {/*        ENROLMENT*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <button className="customToggle"*/}
                                        {/*            onClick={() => navigateTo('/Event_Viewer')}>*/}
                                        {/*        Event Viewer*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}

                                        <li>
                                            <button className="customSpacer"></button>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        )}


                        <div className="profile-container">

                            <a className="username">
                                {window.sessionStorage.getItem("username")}
                            </a>

                            <a className="header-button">
                                <i onClick={() => navigateTo('/Portal/Personal-Profile')}
                                   className="fa fa-user"></i>
                            </a>
                            <a className="header-button">
                                {isLoggedIn ? (
                                    <i onClick={handleLogout}
                                       className="fa fa-lock-open"></i>
                                ) : (
                                    <i onClick={() => navigateTo('/Login')}
                                       className="fa fa-lock" ></i>
                                )}
                            </a>
                            <a className="header-button">
                                <i onClick={() => navigateTo('')}
                                   className="fa fa-search"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};


export default Header;
