import React, {useContext, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import LoginComponent from '../Components/LoginComponent';
import backgroundImage from "../Images/TPLogoHighRes.png";
import {ConstLink} from "../ConstLink";
import { useNavigate, useLocation } from 'react-router-dom';
import logError from "../Components/Logger";  //  error handler



export default function LoginPage() {

    const { isLoggedIn, setIsLoggedIn } = useContext(ConstLink);
    const navigate = useNavigate();
    const location = useLocation(); //error handler

    // const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => window.sessionStorage.setItem("ipAddress", data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);


    return (

        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            <div
                className="image-overlay"
                style={{
                    position: 'absolute',
                    top: 100,
                    right: 100,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    opacity: 0.3,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right center',
                    backgroundSize: 'auto 100%',
                }}
            ></div>
            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',

                }}
            >

                <div>

                    <LoginComponent />

                </div>
            </div>
        </div>
    )
}
