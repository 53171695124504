import React, { useState, useEffect } from 'react';
import '../Styling/index.css';

const CustomSwitch = ({ isEnabled, toggleSwitch }) => {
    const [translateX, setTranslateX] = useState(isEnabled ? '73px' : '0px');

    useEffect(() => {
        setTranslateX(isEnabled ? '0px' : '73px');
    }, [isEnabled]);

    return (
        <div className="switchContainer" onClick={toggleSwitch}>
            <div
                className="animatedContainer"
                style={{ transform: `translateX(${translateX})` }}
            ></div>
            <div className="labelContainer">
                <span className={!isEnabled ? 'label activeLabel' : 'label'}>Feed</span>
                <span className={isEnabled ? 'label activeLabel' : 'label'}>Map</span>
            </div>
        </div>
    );
};

export default CustomSwitch;
