import React, { createContext, useState } from 'react';

const ConstLink = createContext();

const ConstProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(window.sessionStorage.getItem("userLoggedIn"));

    const [isActive, setIsActive] = useState(false);

    const [quickMenu, setQuickMenu] = useState([
        [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null]
    ]);
    // const [quickMenu1, setQuickMenu1] = useState(null);
    // const [quickMenu2, setQuickMenu2] = useState(null);
    // const [quickMenu3, setQuickMenu3] = useState(null);
    // const [quickMenu4, setQuickMenu4] = useState(null);
    // const [quickMenu5, setQuickMenu5] = useState(null);
    // const [quickMenu6, setQuickMenu6] = useState(null);

    const [ stepButtons, setStepButtons ] = useState([
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null]
    ]);


    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const closeHeaderMenu = () => {
        setIsActive(false)
    };

    // const setQuickMenu = (Item1, Item2, Item3, Item4, Item5, Item6) => {
    //
    //     setQuickMenu1( Item1[0] );
    //     setQuickMenu2( Item1[1] );
    //     setQuickMenu3( Item3 );
    //     setQuickMenu4( Item4 );
    //     setQuickMenu5( Item5 );
    //     setQuickMenu6( Item6 );
    //
    // };

    const yourFunctionHere = () => {
        // Function Code...
    };

    return (
        <ConstLink.Provider value={{
            isActive,
            toggleMenu,
            closeHeaderMenu,
            isLoggedIn,
            setIsLoggedIn,
            // quickMenu1,
            // quickMenu2,
            // quickMenu3,
            // quickMenu4,
            // quickMenu5,
            // quickMenu6,
            stepButtons,
            setStepButtons,
            setQuickMenu,
            quickMenu,

        }}>
            {children}
        </ConstLink.Provider>
    );
};

export { ConstProvider, ConstLink };






