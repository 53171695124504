import React, {useContext, useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ConstLink} from "../ConstLink";
import { Document, Page, pdfjs} from "react-pdf";
import Bluey from "Components/Bluey"
import pdfFile from 'Components/document.pdf';
import logError from "../Components/Logger";  //  error handler

// // import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { DragDropContext } from 'react-beautiful-dnd';
// import initialData from 'initial-data';
// import Column from 'column';
// import DragOrder from 'Components/DragOrder';

export default function Test() {

    const navigate = useNavigate();
    const location = useLocation(); //error handler
    const { setQuickMenu, isLoggedIn, setStepButtons } = useContext(ConstLink);
    const [pageName, setPageName] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfPath, setPdfPath] = useState(pdfFile);
    // const [state, setState] = useState(initialData);
    const initialItems = [
        { id: "5", content: "Step 5" },
        { id: "4", content: "Step 4" },
        { id: "2", content: "Step 2" },
        { id: "3", content: "Step 3" },
        { id: "1", content: "Step 1" },
    ];

    // const onDragEnd = result => {
    //     // TODO: reorder the columns here
    // };

    if ( !isLoggedIn ) {
        navigate('/login')
    }


    const [items, setItems] = useState(initialItems);

    // function handleOnDragEnd(result) {
    //     if (!result.destination) return;
    //
    //     const reorderedItems = Array.from(items);
    //     const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    //     reorderedItems.splice(result.destination.index, 0, reorderedItem);
    //
    //
    //     setItems(reorderedItems);
    // }


    useEffect(() => {
        updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        // Asynchronous download PDF
        pdfjs.getDocument(pdfPath).promise.then(function(pdf) {
            console.log("Number of Pages: ", pdf.numPages);
            setNumPages(pdf.numPages);

            for(let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                pdf.getPage(pageNum).then(function(page) {
                    // Returns a promise of an object with an items array which contains our text
                    return page.getTextContent();
                }).then(function(textContent) {
                    textContent.items.forEach(function(textItem) {
                        console.log(textItem.str); // Log each item's text
                    });
                });
            }
        });
    }, [pdfPath]); // Re-run effect when pdfPath changes

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    const updateStepButtons = () => {
        setStepButtons([
            'Add More Buttons', null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null
        ]);
    }

    return (
        <div>
            <Bluey />

            {/*<div className={"page-common"} style={{ marginTop: 100, zIndex: 100 }}>*/}
            {/*    {pageName}*/}

            {/*    <Document*/}
            {/*        file={pdfPath}*/}
            {/*        onLoadSuccess={({ numPages }) => setNumPages(numPages)}*/}
            {/*    >*/}
            {/*        <Page pageNumber={1} />*/}
            {/*    </Document>*/}

            {/*</div>*/}

            {/*<DragDropContext onDragEnd={onDragEnd}>*/}
            {/*    {state.columnOrder.map(columnId => {*/}
            {/*        const column = state.columns[columnId];*/}
            {/*        const tasks = column.taskIds.map(taskId => state.tasks[taskId]);*/}

            {/*        return <Column key={column.id} column={column} tasks={tasks} />;*/}
            {/*    })}*/}
            {/*</DragDropContext>*/}

            {/*<DragOrder />*/}

            {/*<p>Page {pageNumber} of {numPages}</p>*/}

        </div>
    );
}


// =============================== To Do =================================== //



// =============================== Bugs =================================== //
