import React, { useContext, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FaFacebook, FaEnvelope } from 'react-icons/fa';
import { IoMdSchool } from 'react-icons/io';
import '../Styling/footer.css';
import { ConstLink } from '../ConstLink';

function Footer() {

    const navigate = useNavigate();

    const {
        isLoggedIn,
        closeHeaderMenu,
        quickMenu,
    } = useContext(ConstLink);

    // window.onload = function() {
    //     const menuButton = document.getElementById("open-menu");
    //     menuButton.click();
    // };

    function toggleFooterMenu() {
        const menuContainer = document.querySelector('.menu-container');
        const pageContent = document.querySelector('.page-content');

        // Check if the menu is currently open
        const isOpen = menuContainer.classList.contains('open');
        // toggleHeaderMenu();

        if (isOpen) {
            // Close the menu
            menuContainer.classList.remove('open');
            pageContent.classList.remove('open-menu');
        } else {
            // Open the menu
            // toggleMenu();
            closeHeaderMenu();
            menuContainer.classList.add('open');
            pageContent.classList.add('open-menu');
        }
    }

    const navigateTo = (address) => {
        console.log(address);
        toggleFooterMenu()
        navigate(address);
    };

    return (
        <div>

            <div className="footer-container">

                <div className="footer">

                    {/*<div className="footer-icons">*/}
                    {/*    <a href="https://www.facebook.com/virologyst"><FaFacebook className="footerIcon" /></a>*/}
                    {/*    <a href="mailto:virologyst@outlook.com"><FaEnvelope className="footerIcon" /></a>*/}
                    {/*    <a href="https://www.qut.edu.au"><IoMdSchool className="footerIcon" /></a>*/}
                    {/*</div>*/}

                    <p className="copyright" style={{}}>
                        Training Professionals Learning Portal &copy;
                    </p>

                    {/*<div className="menu-container">*/}

                    {/*    {isLoggedIn && (*/}

                    {/*        <ul id="menu">*/}
                    {/*            <a className="menu-button icon-plus" id="open-menu" onClick={toggleFooterMenu} href="#menu" title="Show navigation"></a>*/}
                    {/*            <a className="menu-button icon-minus" onClick={toggleFooterMenu} href="#0" title="Hide navigation"></a>*/}

                    {/*            {quickMenu[2] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[2][1])}>*/}
                    {/*                    {quickMenu[2][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}

                    {/*            {quickMenu[1] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[1][1])}>*/}
                    {/*                    {quickMenu[1][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}

                    {/*            {quickMenu[3] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[3][1])}>*/}
                    {/*                    {quickMenu[3][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}

                    {/*            {quickMenu[4] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[4][1])}>*/}
                    {/*                    {quickMenu[4][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}

                    {/*            {quickMenu[5] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[5][1])}>*/}
                    {/*                    {quickMenu[5][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}

                    {/*            {quickMenu[0] && (*/}
                    {/*                <li className="menu-item" onClick={() => navigateTo(quickMenu[0][1])}>*/}
                    {/*                    {quickMenu[0][0]}*/}
                    {/*                </li>*/}
                    {/*            )}*/}
                    {/*        </ul>*/}


                    {/*    )}*/}

                    {/*</div>*/}

                </div>
            </div>
        </div>
    );
}

export default Footer;
