import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ConstLink } from "../ConstLink";
import Bluey from '../Components/Bluey';
import logError from "../Components/Logger";  //  error handler



export default function EventViewer() {
    const navigate = useNavigate();
    const { isLoggedIn } = useContext(ConstLink);
    const [eventID, setEventID] = useState('');
    const [eventData, setEventData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation(); //error handler
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    const requestBody = JSON.stringify();
    const endpoint = 'https://localhost:3001/errors/getEvent';

    const handleSearch = async () => {
        setLoading(true);
        setError('');
        const requestBody = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ 'eventId': eventID })
        };



        try {
            const response = await fetch(endpoint, requestBody);
            const apiData = await response.json();

            if (!response.ok) {
                throw new Error(apiData.message || 'Failed to fetch event data');
            }
            setEventData(apiData);
            console.log("Event Details:", apiData);
        } catch (error) {
            // setError(err.message || 'An error occurred while searching for the event.');
            // console.error('Fetch error:', err);
            logError({
                page: location.pathname,
                dataSubmitted: requestBody,
                errorData: error.data,
                errorCode: error.errorCode,
                errorStack: error.stack,
                errorMsg: error.message,
                errorDesc: 'We were unable to get retrieve the event', // Detailed description of the error
                endpoint: endpoint,
            }).then(response => {
                console.log('Server responded with:', response);
                setMsg(`We were unable to retrieve the events details. Event ID: ${response.insertedId}`); // Example of using the response
                setMsgCode(2);
            }).catch(err => {
                console.error('Error logging failed:', err);
                setMsg('Failed to communicate with error logging server');
                setMsgCode(3);
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Bluey/>
            <div className="page-common" style={{marginTop: 100, zIndex: 1}}>
                <h1 style={{fontSize: '2rem'}}>Event Viewer</h1>  {/* Increased font size for header */}
                <input
                    type="text"
                    value={eventID}
                    onChange={(e) => setEventID(e.target.value)}
                    placeholder="Enter Event ID"
                    style={{fontSize: '1rem', marginBottom: '0.5rem'}} // {/* Standard font size for input */}
                />
                <button onClick={handleSearch} disabled={loading} style={{fontSize: '1rem'}}>
                    {loading ? 'Loading...' : 'Search'}
                </button>
                {error && <div style={{
                    color: 'red',
                    fontSize: '1rem'
                }}>{error}</div>} {/* Standard font size for error messages */}
                {eventData && (
                    <div>
                        <h2 style={{fontSize: '1.5rem'}}>Event
                            Details:</h2>  {/* Slightly larger font size for subheading */}
                        <ul>
                            {Object.entries(eventData).map(([key, value]) => (
                                <li key={key} style={{
                                    textAlign: 'left',
                                    fontSize: '1rem'
                                }}>  {/* Standard font size for list items */}
                                    <strong>{key.replace(/([A-Z])/g, ' $1').trim()}: </strong>
                                    {value !== null ? value.toString() : 'N/A'}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>

    );
}

